.salesforcechat-icon {
    @extend .circle, .cursor-hand, .white;
    svg {
        height: 2rem;
        margin: 0.9rem;
        width: 2rem;
    }
    &.salesforcechat-icon--online {
        @extend .bg-swiOrange;
        &:hover {
            @include hover($swiOrange);
        }
    }
    &.salesforcechat-icon--offline {
        @extend .bg-SwordSteel;
        &:hover {
            @include hover($swordSteel);
        }
    }
}

.salesforcechat-bouncer {
    height: 3.8rem;
    width: 3.8rem;
    align-items: center;
    justify-content: center;
}

.salesforcechat-message {
    @extend .bg-paleDark;
    width: 285px;
    height: 96px;
    padding: 20px;
    bottom: 100px;
}
