.grid {
    display: grid;
}
.justify-items-start {
    justify-items: start;
}
.justify-items-end {
    justify-items: end;
}
.justify-items-center {
    justify-items: center;
}
.justify-items-stretch {
    justify-items: stretch;
}
.grid-align-start {
    align-items: start;
}
.grid-align-end {
    align-items: end;
}
.grid-align-center {
    align-items: center;
}
.grid-align-stretch {
    align-items: stretch;
}
.justify-self-start {
    justify-self: start;
}
.justify-self-end {
    justify-self: end;
}
.justify-self-center {
    justify-self: center;
}
.justify-self-stretch {
    justify-self: stretch;
}
.place-center {
    place-items: center;
}

@mixin grid-row($row) {
    grid-row: $row;
}

@mixin grid-column($column) {
    grid-column: $column;
}
