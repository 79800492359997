.sidebar__selection {
    @extend .relative;
    @extend .bg-white;
    @extend .border-left;
    @extend .border-color-grey1;
    @extend .full-height;
    @extend .p3;
    @extend .flex;
    @extend .flex-column;
    @extend .transparent;
    @extend .no-shrink;

    width: 300px; // taken from the mockups
    transition: margin-right $slow-easing, opacity $slow-easing;
    //Arbitrary sidebar width taken from the mockups
    margin-right: -300px;
    border-top-right-radius: $tile-radius;
    border-bottom-right-radius: $tile-radius;
    gap: $space-3;
    max-height: 100%;

    &.sidebar__selection--open {
        margin-right: 0;
        opacity: 1;
    }

    #nodeLabel {
        max-width: 90%;
    }
}

.vc-viz-visual-explain-plan {
    @extend .view;
    @extend .flex;
    @extend .flex-column;
    gap: $space-3;
    height: calc(100vh - #{$header-height});

    .query-box {
        max-height: 250px;
        overflow: scroll;
    }
}

.sidebar__selection__table__key {
    width: 180px; //arbitrary number to avoid early truncation of the key values
}
