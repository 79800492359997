header {
    height: $header-height;
}

//makes header shortcuts align to the right
.header-no-environment .header__command-bar {
    @extend .justify-end;
}

.header__tool {
    height: $header-height;
    &:hover {
        @extend .z2;
    }
}

.header__tool__dropdown {
    @extend .flex, .items-center, .justify-center, .rounded, .bg-MoonCrater, .fz18;
    height: 30px;
    width: 30px;
    &:hover {
        @include hover($dark);
    }
}

.header__tool__dropdown .dropdown {
    @extend .flex, .items-center, .justify-center, .full-width, .full-height;
}

.header__tool__dropdown .dropdown--open {
    @extend .bg-white, .z3, .flex, .full-width, .justify-center, .items-center, .rounded-top, .pt2, .top-2;
    @include hover($white);
    padding-bottom: 20px;
    //These values below aren't round to compensate the 2px needed for the shadow to be covered
    height: 45px;
    top: 7px;
}

.header__tool__dropdown .dropdown--open > .icon__wrapper {
    @extend .swiOrange;
}

.header__tool__dropdown .dropdown--open ~ .dropdown__content {
    top: calc(100% + 10px);
    @extend .mt1;
}

.header__tool__dropdown .dropdown > .icon__wrapper {
    @extend .fz18, .white;
}
