$border-width: 1px;
$border-radius: 6px;

.border {
    border: $border-width solid;
}

.border-top {
    border-top: $border-width solid;
}

.border-right {
    border-right: $border-width solid;
}

.border-bottom {
    border-bottom: $border-width solid;
}

.border-left {
    border-left: $border-width solid;
}

.rounded {
    border-radius: $border-radius;
}

.circle {
    border-radius: 50%;
}

.capsule {
    border-radius: 500px;
}

.rounded-top {
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
}

.rounded-right {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}

.rounded-bottom {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
}

.rounded-left {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
}

.not-rounded {
    border-radius: 0;
}

.no-border {
    border: none;
}

[class*=' border-bottom-padding-']:before {
    @extend .absolute, .bottom-0;
    content: '';
    height: 1px;
}

.border-color- {
    &white {
        border-color: $white;
    }
    &grey0 {
        border-color: $grey0;
    }
    &grey05 {
        border-color: $grey05;
    }
    &grey1 {
        border-color: $grey1;
    }
    &grey2 {
        border-color: $grey2;
    }
    &grey3 {
        border-color: $grey3;
    }
    &dark {
        border-color: $dark;
    }
    &MainColor {
        border-color: $mainColor;
    }
    &DarkMainColor {
        border-color: $darkMainColor;
    }
    &orange {
        border-color: $orange;
    }
    &red {
        border-color: $red;
    }
    &blue {
        border-color: $blue;
    }
    &green {
        border-color: $green;
    }
    &semi-dark {
        border-color: $semiDark;
    }
}

.border-opacity {
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
}

.border-thick {
    border-width: 2px;
}

.border--dropdown {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.border-viz-chart {
    /* Fallback dashed border
       - the 4px width here is overwritten with the border-image-width (if set)
       - the border-image-width can be omitted below if it is the same as the 4px here
    */
    border-left: dashed 1px #ddd;
    /*border-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAKUlEQVR42mNgYGD4jwUTC7DqpZmBlIIhZOAoGAWjgJ5ZZgiWNoOzxAYAZ/s/wRzWMgkAAAAASUVORK5CYII=")
        2 round;*/
}
