.switch-on-off-icon {
    @extend .capsule;
    height: 18px;
    width: 32px;
    background-color: $grey2;
    transition: background-color $duration;
    &:before {
        transition: right $duration, left $duration;
        content: '';
        height: 14px;
        width: 14px;
        @extend .circle, .absolute;
        background-color: $white;
        left: 2px;
        top: 2px;
        right: auto;
    }
}

.switch-on-off-icon.on {
    background-color: $mainColor;
    &:before {
        @extend .left-auto;
        right: 2px;
    }
}
