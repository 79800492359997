select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

.vc-select {
    @extend .relative, .cursor-hand, .flex, .items-center;
}

.vc-select--table {
    @extend .flex, .relative;
    .open {
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
    }
    .vc-dropdown-toggle {
        @extend .p2, .user-select;
    }
}

.vc-select--table .open .vc-dropdown-toggle {
    @extend .relative, .z2, .dark, .bg-white;
}

.vc-select--table .open .dropdown__content {
    min-width: 100%;
    li {
        @extend .flex;
        &:hover {
            transition: background-color $duration;
            background-color: lighten($mainColor, 50%);
        }
    }
}

.dropdown__content--icon-dropdown {
    //arbitrary number to allow the alignment of the dropdown content background with the "gear" icon background
    top: 33px;
}

.vc-select > select {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 25px;
    @extend .relative, .cursor-hand, .flex-grow-1;
}
