.breakDown_progress_bar {
    @extend .relative;
    width: 305px;
    min-width: 193px;
    height: 12px;
}

.host_breakdown-percentage {
    @extend .dark, .fz13, .nowrap, .mr3, .ml2, .right-align;
    min-width: 65px;
}

.host_title {
    @extend .flex, .items-center, .mainColor, .overflow-hidden, .nowrap;
    text-overflow: ellipsis;

    &:hover {
        @extend .underline;
    }
}

.host_breakdown {
    @extend .px2, .flex, .items-center, .justify-between, .border-bottom, .border-color-grey1;
    height: 60px;
}

.host_breakdown_widget {
    height: 466px;
}
