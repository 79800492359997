.impersonator {
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 1px 3px 5px 0 rgba(0, 0, 0, 0.3);
}

.impersonator__exit,
.impersonator,
.impersonator__result-item:last-child {
    @extend .rounded-bottom;
}

.impersonator__user {
    width: 500px;
}

.impersonator__list {
    max-height: 90vh;
    @extend .overflow-auto;
}

.impersonator__result-item {
    &--selected {
        background-color: lighten($mainColor, 40%);
    }
    &:last-child {
        @extend .mb2;
    }
}
