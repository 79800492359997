.interval-dropdown {
    height: 30px;
    @extend .flex, .items-center, .white, .bg-MoonCrater, .p2, .rounded, .relative, .z1, .vc-dropdown-toggle;
}

.interval--open {
    height: $header-height;
}

.interval--disabled {
    @extend .half-opacity, .cursor-normal, .user-select, .pointer-events;
}

.interval--open .interval-dropdown {
    padding-bottom: 30px;
    height: $header-height;
    @extend .top-2;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.interval--open .interval-dropdown {
    @extend .bg-grey05, .dark;
    .icon__wrapper svg.icon {
        transition: transform $long-duration;
        transform: rotate(180deg);
        fill: $mainColor;
    }
}

.slider__range {
    //Ball height
    height: 19px;
    &:after {
        @extend .absolute, .full-width;
        content: '';
        border-radius: 4px;
        height: 6px;
        background-color: $grey2;
        bottom: 7px;
    }
}

.slider__range__pointer {
    height: 19px;
    margin: 0 0 -8px -8px;
    width: 19px;
}

.slider__scale__marks__tick__label {
    left: -50%;
    transform: translateX(-50%);
    //compensates tick positioning on the border of the element box, so the ball is actually centered with the tick
    margin-left: 1px;
}

.slider__scale__marks__tick__mark {
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 4px;
}

.vc-datetime-input-container__input--date {
    width: 130px;
    &.error {
        border: 1px solid $red;
    }
}

.vc-datetime-input-container__input--time {
    width: 100px;
    &.error {
        border: 1px solid $red;
    }
}

.interval-dropdown-content__interval-offset {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: $space-3;
}

.interval-dropdown-content__interval-offset__start-only {
    grid-column: 1 / 3;
}

.datetime__label__end-now {
    //alignment of the end now date when ending now is enabled
    right: 130px;
    top: 3px;
}

/*Calendar*/
.calendar {
    @extend .user-select;
    -webkit-touch-callout: none;

    ol.days {
        li {
            float: left;
            height: 27px;
            line-height: 27px;
            width: 14.28%;

            &:before {
                content: '';
                width: 32px;
                height: 32px;
                @extend .absolute, .circle;
                background-color: transparent;
                transform: translateX(-50%);
                left: 50%;
                top: -2px;
                transition: background-color $duration;
            }

            &.not-this-month {
                visibility: hidden;
            }
            &.selected,
            &:not(.selected):hover {
                @extend .white, .cursor-hand;
                &:before {
                    background-color: $mainColor;
                }
            }
            &:not(.selected) {
                @extend.cursor-normal;
            }
            &.future {
                @extend .grey2, .pointer-events;
                &:hover {
                    @extend .white;
                }
            }
        }
    }
}
