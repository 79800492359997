// -----------
// RESET
// -----------
* {
    background: transparent;
    border: 0;
    font-size: 100%;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

:root {
    font-size: 16px;
}

abbr[title],
dfn[title] {
    text-decoration: underline dotted;
}

//Dotted line renders badly on chrome
@supports (-webkit-appearance: none) {
    abbr[title],
    dfn[title] {
        text-decoration: underline solid;
    }
}

table {
    border-spacing: 0;
    display: table;
}

html {
    box-sizing: border-box;
    line-height: 1;
}

//Configure all the elements to use the box sizing rendering method, which is more appropriate for this type of application
//http://www.paulirish.com/2012/box-sizing-border-box-ftw/

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: 'Nunito', sans-serif;
    font-rendering: optimizeLegibility;
    font-size: 0.875rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $dark;
    background-color: $grey0;
}

// Form elements
form,
fieldset,
input,
select,
textarea {
    font-family: 'Nunito', sans-serif;
}

input,
textarea,
select {
    background-color: $grey05;
    border: 1px solid $grey1;
    border-radius: 3px;
    color: $dark;
    font-size: 0.825rem;
    height: $form-element-height;
    outline: 0;
    padding: 0 $space-1;
    transition: border-color $long-duration;
    &:focus {
        border-color: $mainColor;
        -moz-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
}

input:disabled,
input[disabled],
input[disabled='disabled'],
input.disabled,
select:disabled,
select[disabled],
select[disabled='disabled'],
select.disabled,
textarea:disabled,
textarea[disabled],
textarea[disabled='disabled'],
textarea.disabled {
    background-color: $grey2;
    color: $grey3;
    cursor: default;
}

textarea {
    padding: 2px 7px;
    resize: vertical;
}

//Default styling override to avoid differences with our font styling
button {
    font-family: inherit;
}

//Firefox red glow removal
:-moz-ui-invalid:-moz-focusring:not(output) {
    box-shadow: none;
}
:-moz-ui-invalid:not(output) {
    box-shadow: none;
}
//Placeholders

:-moz-placeholder {
    color: $grey3;
    opacity: 1;
}
::-moz-placeholder {
    color: $grey3;
    opacity: 1;
}
:-ms-input-placeholder {
    //The !important is for edge and IE11 versions
    color: $grey3 !important;
}
::-webkit-input-placeholder {
    color: $grey3;
}

/* Anchors */

a {
    cursor: pointer;
    color: $mainColor;
    &[href] {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

/* Code tags */
pre {
    background: #4f4f4f;
    color: white;
    display: block;
    font-family: monospace, sans-serif;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: $space-3;
    margin: $space-1 0;
    overflow: auto;
    padding: $space-1;
}

code {
    color: $dark;
    font-family: monospace, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 24px;
    word-break: break-word;
}

kbd:extend(.monospace, .rounded, .p1, .inline-flex, .justify-center, .items-center) {
    min-width: 30px;
    min-height: 30px;
    border: 2px solid rgba(0, 0, 0, 0.15);
}

strong {
    font-weight: bold;
}
