@media (max-height: 750px) {
    //This prevents super small windows
    .menu__item__title,
    .settings__nav .navigation__group__title + .menu__item__title__list .menu__item__title {
        height: 35px;
    }
    .navigation__group__title .menu__item__title__icon {
        font-size: 1.25rem;
    }
    .menu__item__title__icon,
    .settings__nav .navigation__group__title + .menu__item__title__list .menu__item__title__icon {
        font-size: 1.25rem;
        &.menu__item__title__icon--upgrade {
            font-size: 0.8125rem;
        }
        &.menu__item__title__icon--quick-add {
            font-size: 1.5rem;
        }
    }
    .menu__item__title__beta {
        top: 20px;
        left: 15px;
    }
    .extra-notification {
        width: 10px;
        height: 10px;
    }

    .sidebar:not(.sidebar--collapsed) .env-selector__toggler__org-name {
        display: none;
    }

    .sidebar .env-selector {
        //arbitrary number taken from the mockup
        height: 50px;
    }

    .env-selector .dropdown__content {
        &:after {
            top: 9px;
        }
    }
    .sidebar.sidebar--collapsed .sidebar__toggle {
        //arbitrary positioning of the sidebar toggle
        position: relative;
        width: 35px;
        height: 35px;
    }
}
