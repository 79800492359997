.loader {
    @extend .flex, .relative, .full-height, .overflow-hidden;
    height: 14px;
    border-radius: 2px;
    background-color: rgba(38, 46, 48, 0.2);

    &::before {
        content: '';
        @extend .block, .absolute, .full-height, .full-width;
        left: -200%;
        background: linear-gradient(to right, transparent 0, rgba(255, 255, 255, 0.4) 50%, transparent 100%);
        animation: shine 1s infinite;
    }
}

.loader--mainColor {
    background-color: rgba(44, 175, 184, 0.2);
}

.loader--lightGrey {
    background-color: rgba(109, 122, 125, 0.2);
}

.loader--thin {
    height: 6px;
}

.loader--thick {
    height: 20px;
}

.loader.loader--paragraph {
    @extend .capsule, .relative;
    min-height: 25px;
    width: 75%;
    background-color: #2cafb8;
    opacity: 0.3;
}

.loader.loader--paragraph ~ ul .loader--paragraph {
    @extend .full-width;
    opacity: 0.2;
    & + .loader--paragraph {
        width: 55%;
        opacity: 0.1;
    }
}

.loader--visualization {
    @extend .loader--mainColor, .full-height, .relative;
    min-height: $space-4;
    &::before {
        animation: none;
    }
}

.loader--visualization__icon {
    @extend .absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
