.new-feature .icon__wrapper {
    @extend .relative;
    &:before {
        content: '';
        @extend .circle, .absolute, .centerXY, .bg-white;
        height: 50%;
        width: 50%;
    }
    > .icon {
        @extend .relative, .z1;
    }
}

.new-feature .dropdown--open {
    @extend .bg-transparent, .p0;
    box-shadow: none;
}

.new-feature .dropdown__content {
    top: 3px;
}

.new-feature__content {
    //arbitrary number taken from the mockups
    width: 265px;
}

.new-feature__content__actions__dismiss {
    @include primary(#535c5e);
    height: 30px;
    line-height: 30px;
}

.new-feature__content__title {
    letter-spacing: 0.16rem;
    border-color: rgba(255, 255, 255, 0.1);
    border-radius: 1px;
}

.feature-badge--expand-right .new-feature__content {
    @extend .left-0, .pr2;
    padding-left: 30px;
    right: initial;
    width: 275px;
}
