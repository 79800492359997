.members-table__find {
    width: 310px;
}

.members-table__select .open .members-table__select__button {
    @extend .bg-white, .mainColor;
    svg {
        fill: $mainColor;
    }
}

.add-to-team-list {
    max-height: 40vh;
}

.add-to-team-list__item:hover {
    background-color: lighten($mainColor, 50);
}

.add-to-team-list__actions__item:hover {
    @extend .underline;
}

.members-table__select.disabled {
    @extend .pointer-events;
    opacity: 0.5;
}

.vc-table--people {
    //arbitrary number for the columns to be more readable
    min-width: 750px;
}

.vc-table--people td {
    line-height: 36px;
}

.vc-table--people td.invite {
    line-height: $space-3;
}

.vc-table--people__name {
    width: 40%;
}

.vc-table--people__email {
    width: 25%;
}

.vc-table--people__teams {
    width: 35%;
}

.invite-workers .invite-form__form-wrap {
    max-width: 550px;
}

.invite-workers .invite-form__import-wrap {
    max-width: 730px;
}

.settings__form__label--invite-form {
    width: 130px;
}

.settings__form__explain--invite-form {
    margin-left: 140px;
}

.form-messages .alert + .alert {
    @extend .mt3;
}

.invite-form__fieldsets:last-child {
    @extend .mb0;
}

.form__invites {
    @extend .overflow-auto;
    max-height: 50vh;
}

.import-sources__textarea {
    height: 195px;
}

.drop-files__input {
    opacity: 0;
}

.drop-files__icon {
    font-size: 500%;
}

@media (max-width: 1024px) {
    .vc-table--people__gravatar .gravatar--small {
        display: none;
    }
}

.members-table__find {
    width: 310px;
}
