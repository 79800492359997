.tabs--credentials {
    @extend .flex, .border, .border-color-grey1;

    .tabs {
        @extend .flex-column, .border-right, .py2, .border-color-grey1;
        border-bottom: none;
    }

    .tab__heading {
        @extend .flex;
        transition: background-color $duration;
        width: 300px;
        height: 60px;
        &:hover {
            background-color: lighten($mainColor, 50%);
        }
    }

    .tabs--credentials__loading {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .tab__heading__title {
        @extend .self-center, .full-width, .full-height, .items-center, .p0, .no-border;
        &:after {
            @extend .display-none;
        }
    }

    .tab__heading,
    .active .tab__heading__title {
        @extend .lighter, .dark;
    }

    .tab__heading.active {
        background-color: lighten($mainColor, 50%);
    }

    .tab__heading__title vc-icon > svg {
        @extend .h2, .mx3;
        fill: $grey3;
    }
    .credentials-form__editable__input-wrap {
        width: 310px;
    }
}

.credentials-form__editable__input-wrap .settings__form__explain {
    max-width: 310px;
}

.settings__form__actions__message {
    animation: flash 5s cubic-bezier(0.23, 1, 0.32, 1) forwards 1;
}

//Forms label width is changed inside dialogs since it's too big
.settings__section-content .credential__form {
    max-width: 600px;
    @extend .dialog__container__content;
    input,
    textarea,
    select {
        @extend .bg-grey05;
    }
    input:disabled,
    input[disabled],
    input[disabled='disabled'],
    input.disabled,
    select:disabled,
    select[disabled],
    select[disabled='disabled'],
    select.disabled,
    textarea:disabled,
    textarea[disabled],
    textarea[disabled='disabled'],
    textarea.disabled {
        @extend .half-opacity;
        opacity: 0.5;
        background-color: $grey2;
        color: $grey3;
    }
    .popover__content {
        min-width: 300px;
    }
}

@media (max-width: 1200px) {
    .tabs--credentials .tab__heading {
        width: 150px;
        transition: width $long-duration;
    }
}
