.samples__latency__filters {
    .form__input-container__error {
        display: none;
    }
}

.filters__queries__input .popover {
    @extend .ml1;
}

.filters__queries__input .form__input-container,
.filters__multi-input .form__input-container {
    @extend .full-width;
}

.vc-table--samples__sample-link {
    bottom: -9px;
}

.filters__queries__input .form__select {
    width: 110px;
}

.samples__export.disabled {
    @extend .pointer-events, .half-opacity;
}

.export-toggler.samples__export {
    @extend .flex, .z2;
    &--open {
        .dropdown__label {
            @extend .bg-grey05, .shadow;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            clip-path: polygon(-10px -10px, calc(100% + 10px) -10px, calc(100% + 10px) 100%, -10px 100%);
        }
        .dropdown__label .mainColor svg {
            transition: transform $long-duration;
            transform: rotate(180deg);
        }
        .dropdown__content {
            min-width: 0;
        }
    }
}

.samples__export .dropdown__label,
.samples__export button {
    @extend .cursor-hand;
}

.samples__export .config-list__label__content {
    width: 320px;
    right: 100%;
    @extend .rounded-bottom, .rounded-left;
}

.form__samples-filters {
    @extend .flex, .items-end, .flex-grow-1, .full-height, .full-width, .p2;
}

.form__samples-filters .popover__content {
    min-width: 300px;
}

@media (max-width: 1777px) {
    .form__samples-filters {
        flex-wrap: wrap;
        padding-bottom: 0;
    }
    .filters__queries__input {
        width: 33%;
        .flex-wrap {
            flex-wrap: nowrap;
        }
        .fz10.uppercase.bold.mr2 {
            width: 120px;
        }
    }
    .form__samples-filters > * {
        margin-bottom: $space-2;
    }
}
