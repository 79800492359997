.settings__form--preferences {
    //arbitrary number to set the inputs width
    max-width: 750px;
}

.settings__form__input--event-settings-small {
    width: 100px;
}

.settings__form__label__input-pre-message {
    width: 135px;
}

.settings__form__actions--delete-samples {
    margin-left: 0;
    max-width: 750px;
}

.day-list__day {
    @extend .mr2, .mb2;
    min-width: 55px;
}

.period__item {
    width: 120px;
    height: 30px;
}

@media (max-width: 1225px) {
    //Here we create 2 rows of 5 columns since we have 10 periods. From 0:00 to 12:00 and from 12:00 to 0:00
    .period {
        display: grid;
        grid-template-columns: repeat(5, 59px);
    }

    .period__item {
        height: 35px;
        position: relative;
    }

    //This is to prevent having a period without a starting time (since we divide the day in half we use 12:00 as the content)
    .period__item:nth-child(6)::before {
        content: '12:00';
        position: absolute;
        left: -59px;
    }

    //Since the labels are aligned to the right we need the second row to be vertically aligned with the first row
    .period__item:nth-child(n + 6) {
        margin-left: 59px;
    }
}
