.autocomplete-select {
    //prevents component collapse when there are no available items
    min-height: 42px;
}

.autocomplete-select .autocomplete-select__capsule {
    &:first-child {
        @extend .mt2;
    }
    &:last-child {
        @extend .mb2;
    }
}

.autocomplete-select__capsule__item-name {
    padding-top: 3px;
    padding-bottom: 3px;
}

.autocomplete-select__capsule__close {
    width: 16px;
    height: 16px;
    vc-icon {
        //optical alignment with the circle
        @extend
            .relative,
            .align__top--1;
    }
}

.autocomplete-select__capsule__dropdown-label {
    color: #afdee1;
    svg {
        fill: #afdee1;
    }
}

.autocomplete-select__capsule__dropdown__list__item:hover {
    transition: background-color $duration;
    background-color: #eaf7f8;
}
