.hosts-selector {
    width: 33vw;
    min-width: 550px;
}

.hosts-selector__filter-mode {
    @extend .mr2, .shadow, .rounded, .absolute, .top-0;
    right: 100%;
}

.hosts-selector__filter-mode__dropdown {
    @extend .relative, .flex, .justify-center, .rounded, .overflow-hidden;
}

.host-filter-mode__dropdown__list__item {
    min-width: 115px;
    height: 30px;
}

.host-filter-mode__dropdown__list__item:hover {
    @extend .bg-grey1;
}

.host-filter-mode__dropdown__list__item--selected {
    button {
        @extend .mainColor, .bold, .relative;
        &:before {
            content: '';
            @extend .absolute, .left-0, .full-height, .bg-MainColor;
            width: 4px;
        }
    }
    //selected item hover UX
    &.host-filter-mode__dropdown__list__item:hover {
        @extend .bg-grey05;
        button {
            @extend .cursor-normal;
        }
    }
}

.hosts-selector--disabled {
    @extend .half-opacity, .cursor-normal, .user-select, .pointer-events;
}

.hosts-selector--error::after {
    content: '';
    @extend .absolute, .top-0, .full-height, .full-width, .border, .border-color-red, .rounded, .pointer-events;
}

.hosts-selector--error .host-filter__input__tag-list__input-item input {
    @extend .red;
    height: 27px;
}

.host-filter__input {
    min-height: $header-element-height;
    //arbitrary height number
    max-height: 30vh;
}

.hosts-selector.hosts-selector--expanded {
    .host-filter__input {
        @extend .rounded-top;
    }
    .host-filter__input__tag-list,
    .host-filter__input__tag-list__input-item input {
        @extend .bg-grey05;
    }
}

.hosts-selector:not(.hosts-selector--expanded) {
    .host-filter__input {
        @extend .rounded, .overflow-hidden;
        height: $header-element-height;
    }
}

.host-filter__input--filter-active {
    .host-filter__input__tag-list {
        @extend .bg-grey05;
    }
    .host-filter__input__tag-list__input-item input {
        @extend .bg-grey05;
    }
}

.host-filter__results {
    top: 100%;
    @extend .absolute, .full-width, .bg-grey05, .overflow-hidden, .rounded-bottom, .border--dropdown;
    &.host-filter__results--advanced-mode {
        .host-filter__results__list {
            @extend .mx3, .mb3, .bg-grey1, .rounded;
        }
    }
}

.host-filter__results--advanced-mode__results-title {
    @extend .px3, .pt3, .pb2, .fz14;
}

.host-filter__results__list {
    max-height: 50vh;
}

.host-filter__input__tag-list {
    padding-right: 90px;
    min-height: $header-element-height;
}

.host-filter__input__tag-list > ul {
    padding: 2px;
}

.host-filter__input__tag-list__input-item,
.host-filter__input__tag {
    height: 26px;
    margin-right: 2px;
    margin-bottom: 2px;
}

.host-filter__input__tag {
    padding: 0 25px 0 $space-1;
    @extend .justify-self-stretch;
    button {
        top: $space-1;
        right: $space-1;
    }
    .icon__wrapper {
        @extend .white;
    }
}

.host-filter__input__tag.host-filter__input__tag--ellipsis {
    @extend .justify-center, .bg-semiDark, .white, .rounded, .items-center, .truncate, .relative, .order-1, .px2, .bold;
}

.host-filter-results__item:hover,
.host-filter-results__item--selected {
    transition: background-color $duration;
    @extend .bg-grey1;
}

.tag--hidden,
.host-filter__input__tag--ellipsis {
    display: none;
}

.tag--hidden + .host-filter__input__tag--ellipsis,
// The forced-display class allows the host selector to properly compute the
// width of this element, by obtaining the real width. The width cannot be
// computed for hidden elements, as its width is always `0`.
.host-filter__input__tag--ellipsis.forced-display {
    @extend .inline-flex;
}

.host-filter__input__host-count {
    @extend .italic, .grey3, .absolute, .right-2, .line-height-120, .fz12;
    top: 8px;
    &.host-filter__input__host-count--filter-active {
        right: 33px;
    }
}

.host-filter__input__clear-selection {
    @extend .absolute, .rounded, .bg-HotelExecutive, .align__right--3, .align__top--3, .cursor-hand;
    padding: 3px;
}

.host-filter__results--advanced-mode__help {
    @extend .p3, .rounded, .bg-grey1, .mx3, .my3;
}

.host-filter__results--advanced-mode__help + .host-filter__results--advanced-mode__results-title,
.host-filter__results--advanced-mode__help ~ .host-filter__results__list {
    @extend .display-none;
}

.host-filter__results--advanced-mode__help__table tr {
    td,
    th {
        @extend .border-bottom, .py2, .line-height-120, .regular, .left-align, .border-color-grey2;
    }
    td:first-child {
        min-width: 200px; //arbitrary width taken from the mockups
    }
    td:last-child {
        min-width: 225px; //arbitrary width taken from the mockups
    }
}

.host-filter__results__no-results {
    @extend .center, .break-all, .monospace, .fz14, .grey3, .m3, .p3, .bg-grey1, .rounded;
}

.host-filter__results__autocomplete {
    @extend .absolute, .left-3, .top-2, .z1, .rounded, .px3, .pt3;
    background-color: $grey05;
    box-shadow: 0 0 10px rgba(0, 0, 64, 0.25);
    min-width: 185px;
}

.host-filter__results__autocomplete li {
    &:not(.host-filter__results__autocomplete__title) {
        @extend .mxn1;
    }
    &:last-child {
        @extend .pb2;
    }
}

.host-filter__results__autocomplete__suggestion {
    @extend .p1, .py2, .cursor-hand, .full-width, .left-align, .rounded;
    &:hover {
        @include hover($grey1);
    }
}

// Host Filter inside forms
.hosts-selector.hosts-selector--form:not(.hosts-selector--expanded) .host-filter__input {
    height: $form-element-height;
}

.hosts-selector--form {
    .host-filter__input__tag-list,
    .host-filter__input__tag-list__input-item input {
        @extend .bg-grey05;
    }
    .host-filter__input__tag-list__input-item input {
        @extend .full-width;
    }
    &.hosts-selector:not(.hosts-selector--expanded) .host-filter__input,
    &.hosts-selector.hosts-selector--expanded .host-filter__input {
        border-radius: 3px;
    }
    .host-filter__input {
        @extend .border, .border-color-grey1;
    }
    &.hosts-selector.hosts-selector--expanded .host-filter__input {
        border-bottom: none;
    }
    .host-filter__input__tag-list__input-item > span {
        @extend .display-none;
    }
    .host-filter__input__tag-list > ul {
        padding: 3px 4px 4px;
    }
    .host-filter__input__tag {
        margin-right: 4px;
        margin-bottom: 4px;
    }
    .host-filter__input__host-count {
        top: 9px;
    }
    .host-filter__input__clear-selection {
        top: 4px;
    }
    .host-filter__results__list {
        max-height: 30vh;
    }
}
