.vc-viz-diagram {
    .vc-viz-diagram__zoom-controls {
        @extend .flex;
        @extend .absolute;
        @extend .flex-column;
        gap: $space-1;
        bottom: $space-3;
        left: $space-3;

        button {
            @extend .primary-white;
            fill: $mainColor;
            width: $form-element-height;
            display: grid;
            place-items: center;
            padding: 0;
            border: 1px solid $grey2;
            transition: box-shadow $long-duration, background-color $long-duration;

            .icon {
                height: 2em;
                width: 2em;
            }

            &:focus {
                box-shadow: 0px 0px 0px 1px $lightMainColor;
            }
        }
    }

    .vc-viz-diagram-node {
        transition: opacity $long-duration;

        .vc-viz-diagram-node__rect {
            transition: stroke-width linear 100ms;
            stroke-width: 1px;
        }

        &.vc-viz-diagram-node--selected .vc-viz-diagram-node__rect {
            stroke-width: 1px;
        }
        &.vc-viz-diagram-node--selected > * {
            transition: fill linear 100ms;
        }
        &:hover {
            &:not(.vc-viz-diagram-node--selected) .vc-viz-diagram-node__rect {
                stroke-width: 2px;
            }
        }
    }
}
