html.fullscreen.minimal {
    .secondary {
        height: 0;
    }
    .main {
        @extend .m0;
    }
    header {
        @extend .p0;
    }
    .filters {
        @extend .pr0;
    }

    //charts
    .notebooks__charts {
        @extend .ml0;
    }

    .charts .filters__title {
        @extend .no-border;
    }

    .dashboards,
    .notebooks {
        @extend .pl3;
    }

    //notebooks
    .notebooks__charts .charts__container {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .collection__header--notebook,
    vc-add-notebook-button,
    .filters__view-metrics,
    .notebooks__sidebar,
    .add-notebook-button {
        @extend .display-none;
    }
}
