.alert-error {
    @extend .bg-red;
}

.alert-success {
    @extend .bg-darkGreen;
}

.alert-warning,
.alert-warn {
    @extend .bg-orangeMessage;
}

.alert-info {
    @extend .bg-blue;
}

.alert__close-icon {
    //optical alignment of the X button
    @extend .relative, .align__bottom--1;
}

.alert a {
    @extend .white, .underline;
}

.status-message-wrp__message {
    &.status-major {
        @extend .bg-red;
    }
    &.status-minor {
        @extend .bg-orangeMessage;
    }
    a {
        @extend .white, .bold;
    }
}

.status-message-wrp {
    height: $status-message-height;
}

.show-status-message ~ header {
    & + .main-content-wrp {
        padding-top: $header-height + $status-message-height;
    }
    &:empty + .main-content-wrp {
        padding-top: $status-message-height;
    }
}

.show-status-message ~ .main-content-wrp {
    padding-top: $status-message-height;
}

.show-status-message ~ header {
    top: $status-message-height;
}

.show-status-message ~ .main-content-wrp .secondary {
    top: $status-message-height;
    height: calc(100% - #{$status-message-height});
}

.show-status-message ~ header:empty + .main-content-wrp .secondary.secondary--upgrade {
    height: calc(100% - #{$space-4});
}

//vc-message
.vc-msg-container {
    @extend .fixed, .inline-block, .center, .top-2, .z5;
    transform: translateX(-50%);
    left: 50%;
}

.vc-msg-container + .main-content-wrp .secondary {
    //Saves the space of the missing header when selecting env
    top: $header-height;
}

.vc-msg {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.3);
    width: 460px;
}

.vc-msg__icon {
    box-shadow: inset -3px 0px 3px -2px rgba(0, 0, 0, 0.2);
}

.vc-msg.error {
    .vc-msg__icon {
        @extend .bg-red;
    }
}

.vc-msg.done {
    .vc-msg__icon {
        @extend .bg-darkGreen;
    }
}

.vc-msg.warning {
    .vc-msg__icon {
        @extend .bg-orangeMessage;
    }
}

//SDV component styling, if in the future is used on more places it will need its own stylesheet
.SDV__toggle__icon {
    transition: transform $long-duration;
}

.SDV__toggle__icon--hidden {
    transform: rotate(180deg);
}

.SDV-meaning {
    //approximate height of the container
    max-height: 350px;
    transition: max-height $duration;
}

.SDV-meaning--shown {
    max-height: auto;
}

.SDV-meaning--hidden {
    max-height: 0;
}

.sdv__dropdown__content {
    width: 410px;
}

.install-options .sdv__dropdown__content {
    //dropdown width to fill the whole content
    width: 725px;
}

.vc-msg--fade-out {
    @extend .transparent;
    transition: opacity 0.3s;
}
