.dropdown-disabled .dropdown {
    @extend .bg-grey1, .grey2, .pointer-events;
    .icon {
        fill: $grey2;
    }
}

.dropdown {
    @extend .flex, .items-center, .px2, .z1, .cursor-hand, .rounded;
    height: $form-element-height;
    transition: background-color $duration, color $duration;
}

.dropdown--right .dropdown__content {
    @extend .right-0, .left-auto, .rounded-left, .rounded-bottom;
}

.dropdown--option-included .icon__wrapper {
    @extend .z1;
}

.dropdown--option-included .dropdown__content {
    @extend .right-0, .top-0, .left-auto;
}

.dropdown__content {
    min-width: 220px;
}

.dropdown .icon {
    transition: transform $long-duration;
}

.dropdown--open {
    @extend .bg-white, .z3, .shadow;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
    clip-path: polygon(-10px -10px, calc(100% - -10px) -10px, calc(100% - -10px) 100%, -10px 100%);
}

.dropdown--open .dropdown__arrow-icon {
    transform: rotate(180deg);
}

.dropdown__content {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
    top: $form-element-height;
    animation: drop-down $long-duration forwards;
}

.dropdown--open ~ .dropdown__content {
    @extend .z2;
}

.dropdown--option-included .dropdown {
    height: 24px;
}

.dropdown--option-included .dropdown--open {
    background-color: transparent;
    .icon {
        fill: $white;
    }
}

.view-menu__content__list__item {
    &:hover {
        @extend .bg-MainColor;
    }
}

.dropdown--grey05,
.dropdown--grey05 .dropdown--open {
    @extend .bg-grey05;
}

.dropdown--label-icon .dropdown {
    @extend .pl4, .dark;
}

.dropdown__picklist {
    @extend .relative, .bg-grey0, .border, .border-color-grey1, .flex, .items-center, .justify-between;
    border-radius: 2px;
    width: 100px;
    height: 36px;
}

.dropdown_picklist_content {
    @extend .absolute, .top-4, .bg-white, .rounded, .right-0, .border, .border-color-grey1, .py2, .z1;
    width: 100px;
    min-height: 36px;
}

.dropdown_menu__item {
    @extend .block, .nowrap, .py2, .pl3, .pr4, .cursor-hand;
    &:hover {
        background-color: $grey0;
    }
}

.dropdown-arrow-open {
    transform: rotate(180deg);
}
