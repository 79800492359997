.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.fixed {
    position: fixed;
}
.static {
    position: static;
}
.float-left {
    float: left;
}
.float-right {
    float: right;
}

.top-0 {
    top: 0;
}
.top-1 {
    top: $space-1;
}
.top-2 {
    top: $space-2;
}
.top-3 {
    top: $space-3;
}
.top-4 {
    top: $space-4;
}
.top-auto {
    top: auto;
}

.right-0 {
    right: 0;
}
.right-1 {
    right: $space-1;
}
.right-2 {
    right: $space-2;
}
.right-3 {
    right: $space-3;
}
.right-4 {
    right: $space-4;
}
.right-auto {
    right: auto;
}

.bottom-0 {
    bottom: 0;
}
.bottom-1 {
    bottom: $space-1;
}
.bottom-2 {
    bottom: $space-2;
}
.bottom-3 {
    bottom: $space-3;
}
.bottom-4 {
    bottom: $space-4;
}
.bottom-auto {
    bottom: auto;
}

.left-0 {
    left: 0;
}
.left-1 {
    left: $space-1;
}
.left-2 {
    left: $space-2;
}
.left-3 {
    left: $space-3;
}
.left-4 {
    left: $space-4;
}
.left-auto {
    left: auto;
}

.z1 {
    z-index: 10;
}
.z2 {
    z-index: 20;
}
.z3 {
    z-index: 30;
}
.z4 {
    z-index: 40;
}
.z5 {
    z-index: 50;
}

//Optical aligments because sometimes 0 or 100% is not enough
.align__top--1 {
    top: 1px;
}
.align__top--2 {
    top: 2px;
}
.align__top--3 {
    top: 3px;
}

.align__right--1 {
    right: 1px;
}
.align__right--2 {
    right: 2px;
}
.align__right--3 {
    right: 3px;
}

.align__bottom--1 {
    bottom: 1px;
}
.align__bottom--2 {
    bottom: 2px;
}
.align__bottom--3 {
    bottom: 3px;
}

.align__left--1 {
    left: 1px;
}
.align__left--2 {
    left: 2px;
}
.align__left--3 {
    left: 3px;
}

.centerY {
    top: 50%;
    transform: translateY(-50%);
}

.centerX {
    left: 50%;
    transform: translateX(-50%);
}

.centerXY {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
