.filters {
    @extend .fixed, .flex, .full-width, .z3, .items-center, .border-bottom, .bg-white, .border-color-grey1;
    padding-right: $nav-width;
    height: $header-height + $space-2; //added height to consider scrollbar
    top: $header-height;
    transition: padding-right $long-duration;
}

.filters .share {
    @extend .flex, .items-center, .justify-center, .rounded, .mr3, .self-center, .no-shrink;
    height: 40px;
    width: 40px;
    @include hover($mainColor);
    &:hover > svg {
        fill: $white;
    }
}

.view.view--filters {
    padding-top: $header-height + $space-2; //filters new height is 70px instead of the same as header (60px)
    .filters {
        margin-left: -30px; //this overrides the padding used for the view class
    }
}

.filter__nav__button:not(.filter__nav__button--current):hover {
    transition: background-color $duration;
    background-color: lighten($mainColor, 50%);
}

.share-modal .share-modal__input input {
    @extend .full-width, .bg-white;
}

//Status message is shown
.show-status-message ~ .main-content-wrp .filters {
    top: $header-height + $status-message-height;
}

.filters {
    $form-label-width: auto;
    .form__label {
        width: $form-label-width;
        @extend .mr1;
    }
    .horizontal-layout {
        grid-template-columns: $form-label-width 1fr;
    }
    .form__actions {
        width: $form-label-width;
    }
}
