.settings__billing {
    max-width: 700px;
}

.billing__display-cc {
    background-color: $grey05;
    border-radius: 10px;
    height: 245px;
    width: 350px;
}

.billing__display-cc__title__type {
    font-size: 500%;
}

.billing__display-cc__chip {
    font-size: 300%;
    top: 30%;
}
