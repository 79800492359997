.settings__form--profile {
    //arbitrary number to set the inputs width
    max-width: 600px;
}

.vc-table--security-list td {
    @extend .h4, .lighter;
    height: 60px;
}

.vc-table--security-list__index {
    width: 50px;
}
