.view.view--home {
    @extend .p4;
}

.view--home__filter {
    width: 350px;
}

.view--home__create-new button {
    @extend .primary-mainColor;
    &:hover {
        @include hover($mainColor);
    }
    .icon {
        @extend .icon, .white;
    }
}

.view--home__env-list {
    @extend .grid;
    grid-column-gap: 30px;
    grid-row-gap: $space-3;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    transition: width $duration;
    margin-bottom: 150px;
}

.view--home__env-list__item {
    @extend .flex, .items-center, .relative, .tile, .nowrap;
    height: 75px;
    &:hover .environment-tool {
        opacity: 1;
        transition: opacity $duration;
    }
}

.view--home__env-list__item__icon {
    @extend .flex, .mainColor, .mx3;
    font-size: 34px;
}

.environment-box__env-name {
    @extend .fz18, .capitalize, .regular, .dark, .ml2;
    @include multiline-wrap(2);
}

.environment-tool {
    @extend .fz20, .grey2, .hoverable, .transparent;
}

.health__summary__list.health__summary__list--home {
    grid-template-columns: 1fr 1fr 1fr;
    width: auto;
}

//so first and line columns touch the edge of the grid (left and right), so distance is controlled with the grid siblings
.health__summary__list--home .health__summary__list__count {
    &:first-child {
        @extend .justify-self-start;
    }
    &:last-child {
        @extend .justify-self-end;
    }
}
