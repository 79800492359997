.queries__filters__show .horizontal-layout {
    grid-template-columns: auto 170px;
}

.widget--counter {
    height: 60px;
}

.filter__icon {
    top: 6px;
}

.filter_input {
    @extend .pl2, .mr2;
    padding-right: 30px;
    width: 300px;
}

.queries--digest {
    line-height: 1.5;
    @extend .roboto, .mb3;
}

.toggleable-digest {
    min-height: 1em;
    @extend .overflow-hidden;

    &--closed {
        max-height: 3em;
    }
    &--open {
        max-height: none;
    }
}
