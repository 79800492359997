.dialog--fixed .dialog {
    @extend .items-start;
}

.dialog--fixed .dialog__container {
    //Arbitrary number used as default, this should be addressed in a case by case
    top: 25vh;
}

.dialog__container__header {
    padding: 15px $space-3;
}

.dialog__backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}

.dialog__container {
    min-width: 500px;
    max-width: 80vw;
}
