.integrations__form__fields,
.settings__form--integrations {
    //arbitrary form width value so the input don't look so big
    max-width: 750px;
}

.settings__form--integrations__integration {
    max-width: 500px;
}

.settings__form--integrations__content-wrap {
    height: calc(100% - 53px); //53 is the title height
}

.vc-table--integrations__status .vc-popover {
    max-width: 330px;
    width: 330px;
}

.integration__icon__img {
    left: 23px;
    top: 50%;
    transform: translateY(-50%);
}
