.header__tool__dropdown--notifications {
    @extend .cursor-normal, .fz15, .mr2;
    width: 30px;
}

.notifications__notification {
    height: 12px;
    width: 12px;
}

.dropdown-toggle--new-items {
    @extend .z3;
    top: 18px;
    right: 11px;
}

.header__tool__dropdown.header__tool__dropdown--notifications .dropdown > .icon__wrapper {
    @extend .h2;
}
