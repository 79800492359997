/*Mouse associated actions*/
.cursor-hand {
    cursor: pointer;
}

.cursor-normal {
    cursor: default;
}

@mixin darkSelection($color) {
    background-color: darken($color, 5%);
}

/*Mixins*/
@mixin hover($color) {
    background-color: $color;
    transition: background-color $duration;
    &:hover {
        background-color: lighten($color, 5%);
        @extend .cursor-hand;
    }
}

@mixin hoverdark($color) {
    background-color: $color;
    transition: background-color $duration;
    &:hover {
        background-color: darken($color, 5%);
        @extend .cursor-hand;
    }
}

//wrap text
@mixin text-wrapping($containerWidth) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: $containerWidth;
}

/*Sizing*/
.full-width {
    width: 100%;
}

.half-width {
    width: 50%;
}

.quarter-width {
    width: 25%;
}

.full-height {
    height: 100%;
}

/*Display*/
.block {
    display: block;
}
.table {
    display: table;
}
.table-cell {
    display: table-cell;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-visible {
    overflow: visible;
}
.overflow-scroll {
    overflow: scroll;
}
.overflow-auto {
    overflow: auto;
}
.overflow-x-none {
    overflow-x: hidden;
}
.display-none {
    display: none;
}
.inline-block {
    display: inline-block;
}
.inline-flex {
    display: inline-flex;
}
.inline {
    display: inline;
}
.layout-fixed {
    table-layout: fixed;
}
.grid {
    display: grid;
}
//To visually hide things like form labels in an accessible way, use the .hide utility.
.hide {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
}

.hidden {
    visibility: hidden;
}

.transparent {
    opacity: 0;
}

.half-opacity {
    opacity: 0.5;
}

.pointer-events {
    pointer-events: none;
}

.pointer-events-auto {
    pointer-events: auto;
}

/*Lists*/
.list-reset {
    list-style: none;
    padding-left: 0;
}

//triangle tips
@mixin arrow-tip($color) {
    &:after {
        border-bottom: 15px solid $color;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        content: '';
        position: absolute;
    }
}

@mixin tip-up($color) {
    @include arrow-tip($color);
    &:after {
        top: -15px;
    }
}

@mixin tip-right($color) {
    @include arrow-tip($color);
    &:after {
        right: -15px;
        transform: rotate(90deg);
    }
}

@mixin tip-down($color) {
    @include arrow-tip($color);
    &:after {
        bottom: -15px;
        transform: rotate(-180deg);
    }
}

@mixin tip-left($color) {
    @include arrow-tip($color);
    &:after {
        left: -15px;
        transform: rotate(-90deg);
    }
}

@mixin flip-horizontal {
    -ms-filter: 'FlipH';
    filter: FlipH;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
}

@mixin flip-vertical {
    -ms-filter: 'FlipV';
    filter: FlipV;
    -webkit-transform: scaleY(-1);
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    transform: scaleY(-1);
}

//Shadows
.shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

//Prevent text selection
.user-select {
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
}

//Scrollhint, base color refers to the background color of the scrollable item
@mixin scrollhint($baseColor) {
    & > * {
        background: linear-gradient($baseColor 30%, rgba(red($baseColor), green($baseColor), blue($baseColor), 0)),
            linear-gradient(rgba(red($baseColor), green($baseColor), blue($baseColor), 0), $baseColor 70%) 0 100%,
            linear-gradient(rgba(red($baseColor), green($baseColor), blue($baseColor), 1), rgba(0, 0, 0, 0.07)) 0 100%,
            linear-gradient(rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0));
        background-repeat: no-repeat;
        background-color: $baseColor;
        background-size: 100% $space-4, 100% $space-4, 100% $space-1, 100% $space-1;
        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
    }
}

//Retina Display and Chrome browser bug fix
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
    .scrollhint:not(*:root) {
        position: relative;
        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            height: $space-1;
            //15px is the chrome scrollbar width (this code is only used in the chrome browser using retina)
            width: calc(100% - 15px);
        }
        &:before {
            top: 0;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.07) 0%, rgba(0, 0, 0, 0) 100%);
        }
        &:after {
            bottom: 0;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.07) 0%, rgba(0, 0, 0, 0) 100%);
        }
        & > * {
            background: initial !important;
        }
    }
}

.table-scrollhint {
    @extend .relative;
    &:before,
    &:after {
        @extend .absolute, .full-height, .top-0;
        content: '';
        width: $space-2;
    }
    &:before {
        @extend .left-0;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) $space-2);
    }
    &:after {
        @extend .right-0;
        background: linear-gradient(to left, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) $space-2);
    }
    .vc-table {
        background: linear-gradient(
                to right,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 9px,
                rgba(255, 255, 255, 0) $space-2
            ),
            linear-gradient(
                to left,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 9px,
                rgba(255, 255, 255, 0) $space-2
            );
    }
}

//construction stripes
$yellow-lines: rgba(245, 191, 0, 0.2);
$black-lines: rgba(41, 47, 49, 0.2);

.diagonal-pattern {
    background-size: 30px 30px;
    background-image: -webkit-gradient(
        linear,
        to right bottom,
        to left top,
        color-stop(0, $yellow-lines),
        color-stop(0.25, $yellow-lines),
        color-stop(0.2505, $black-lines),
        color-stop(0.5, $black-lines),
        color-stop(0.505, $yellow-lines),
        color-stop(0.75, $yellow-lines),
        color-stop(0.7505, $black-lines)
    );
    background-image: linear-gradient(
        to left bottom,
        $yellow-lines 0%,
        $yellow-lines 25%,
        $black-lines 25.05%,
        $black-lines 50%,
        $yellow-lines 50.05%,
        $yellow-lines 75%,
        $black-lines 75.05%
    );
}

//Offline mode
.vc-offline {
    .main-header,
    .main-content-wrp {
        @extend .overflow-hidden;
        -webkit-filter: blur(1px) grayscale(100%);
        filter: blur(1px) grayscale(100%);
        z-index: 1;
    }
}

//Sometimes links are not actually anchors
.link {
    @extend .mainColor, .cursor-hand;
    &:hover {
        text-decoration: underline;
    }
}

.tile {
    box-shadow: $tile-shadow;
    border-radius: $tile-radius;
    @extend .bg-white;
}
