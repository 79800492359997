header {
    & + .main-content-wrp {
        padding-top: $header-height;
    }
    &:empty + .main-content-wrp {
        @extend .pt0;
        .secondary > div {
            @extend .full-height;
        }
    }
}

.secondary {
    width: $nav-width;
    transition: width $long-duration;
}

.secondary > div {
    @extend .flex, .flex-column, .full-height, .overflow-hidden;
}

.show-status-message {
    @extend.relative, .z4, .flex;
    & + .show-status-message {
        @extend.z3;
    }
}

.show-status-message ~ .main-content-wrp .secondary.secondary--upgrade > div {
    @extend .full-height;
}

.logo {
    width: $nav-width;
    height: $header-height;
}

.logo > a {
    width: $nav-width-minimized;
}

.logo svg {
    font-size: 250%;
}

.logo svg,
.logo vc-icon {
    @extend .full-width;
}

.logo__name {
    height: $header-height;
    width: 90px;
    left: $nav-width-minimized;
}

.main {
    margin-left: $nav-width;
    transition: margin $long-duration;
}

.view {
    padding: 30px;
}

.view__footer {
    width: calc(100% - #{$nav-width});
    left: $nav-width;
}

.view--salesforce {
    padding-bottom: 80px; //space for intercom icon
}

.page-title {
    //height of the buttons or dropdowns often used in the same line as the title
    height: 42px;
}

//Min width of the app
body {
    min-width: 1024px;
    overscroll-behavior-y: none;
}

.embeddedServiceHelpButton {
    display: none !important;
}
