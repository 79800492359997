.settings__form--assign-teams {
    width: 510px;
}

.settings__form--assign-teams .settings__form__label {
    width: 150px;
}

.vc-table--env-teams .member__delete {
    @extend .hidden;
}
