.flex {
    display: flex;
}
.flex-grow-1 {
    flex-grow: 1;
}
.flex-grow-2 {
    flex-grow: 2;
}
.flex-grow-3 {
    flex-grow: 3;
}
.flex-shrink {
    flex-shrink: 10;
}
.no-shrink {
    flex-shrink: 0;
}
.flex-column {
    flex-direction: column;
}
.flex-column--reverse {
    flex-direction: column-reverse;
}
.flex-row {
    flex-direction: row;
}
.flex-row--reverse {
    flex-direction: row-reverse;
}
.flex-wrap {
    flex-wrap: wrap;
}
.items-start {
    align-items: flex-start;
}
.items-end {
    align-items: flex-end;
}
.items-center {
    align-items: center;
}
.items-baseline {
    align-items: baseline;
}
.items-stretch {
    align-items: stretch;
}
.self-start {
    align-self: flex-start;
}
.self-end {
    align-self: flex-end;
}
.self-center {
    align-self: center;
}
.self-baseline {
    align-self: baseline;
}
.self-stretch {
    align-self: stretch;
}
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.justify-around {
    justify-content: space-around;
}
.justify-evenly {
    justify-content: space-evenly;
}
.content-start {
    align-content: flex-start;
}
.content-end {
    align-content: flex-end;
}
.content-center {
    align-content: center;
}
.content-between {
    align-content: space-between;
}
.content-around {
    align-content: space-around;
}
.content-stretch {
    align-content: stretch;
}
.flex-half {
    flex-basis: 50%;
}
.flex-quarter {
    flex-basis: 25%;
}
.flex-third {
    flex-basis: 33%;
}
.flex-two-thirds {
    flex-basis: 66%;
}
.flex-three-quarters {
    flex-basis: 75%;
}

/* 1. Fix for Chrome 44 bug. https://code.google.com/p/chromium/issues/detail?id=506893 */
.flex-auto {
    flex: 1 1 auto;
    min-height: 0; /* 1 */
    min-width: 0; /* 1 */
}
.flex-1 {
    //This is different to "flex-grow-1 + no-shrink" since it adds a basis of 0% which is nothing but is everything
    flex: 1;
}
.flex-none {
    flex: none;
}
.order-0 {
    order: 0;
}
.order-1 {
    order: 1;
}
.order-2 {
    order: 2;
}
.order-3 {
    order: 3;
}
.order-last {
    order: 99999;
}
