.popover__content {
    // We are constraining the popover to be around 50 char long
    // https://github.com/VividCortex/ng-app/issues/7194
    max-width: 300px;
    @extend .fz14;
}

.popover__content {
    @extend .line-height-120;
    &::before {
        content: '';
        @extend .absolute;
        border: 5px solid $dark;
    }
    &--right,
    &--left {
        @extend .mx2;
        top: 50%;
        transform: translateY(-50%);
        &::before {
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &--right {
        left: 100%;
        &::before {
            left: -10px;
            border-color: transparent $dark transparent transparent;
        }
    }
    &--left {
        right: 100%;
        &::before {
            right: -10px;
            border-color: transparent transparent transparent $dark;
        }
    }
    &--down,
    &--top {
        @extend .my2;
        left: 50%;
        transform: translateX(-50%);
        &::before {
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &--down {
        top: 100%;
        &::before {
            top: -10px;
            border-color: transparent transparent $dark transparent;
        }
    }
    &--top {
        bottom: 100%;
        &::before {
            bottom: -10px;
            border-color: $dark transparent transparent transparent;
        }
    }
}

.popover-date {
    @extend .nowrap;
}
