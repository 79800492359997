.settings__form--api-tokens {
    width: 510px;
}

.vc-table .table-token__expiration,
.vc-table .table-token__created,
.vc-table .table-token__last-used,
.vc-table .table-token__type,
.vc-table .table-token__role {
    @extend.left-align;
    //arbitrary number to maintain column width
    width: 120px;
    min-width: 120px;
}

.vc-table--table-token__rotation__input-copy {
    width: 330px;
    min-width: 330px;
}

.vc-table .table-token__token {
    min-width: 600px;
}

.vc-table td.vc-table--settings--api-tokens__actions {
    @extend .pt2;
}

.vc-table--settings--api-tokens__actions {
    width: 195px;
    min-width: 195px;
}

.vc-table--settings--api-tokens__actions .icon__wrapper {
    @extend .inline-flex;
}

.vc-table--settings--api-tokens__actions__button {
    width: 130px;
}

.create-token-hash__ctn--input {
    min-width: 280px;
}

.vc-table--settings .table-token__rotation {
    @extend .p0;
}

.vc-table--settings td.table-token__rotation {
    height: auto;
    border-color: $grey2;
    background-color: $grey0;
    @extend .pl2;
}

.vc-table.vc-table--table-token__rotation td {
    @extend .fz12, .regular, .no-border, .py2;
    background-color: $grey0;
    height: auto;
}

.vc-table--settings--api-tokens tbody > tr td,
.vc-table--table-token__rotation .vc-table--settings--api-tokens__superseded-row + tr td,
.vc-table--settings--api-tokens th {
    border-bottom-color: $grey2;
}

.vc-table--settings--api-tokens tbody > .vc-table--settings--api-tokens__superseded-row td {
    border-color: transparent;
}

.vc-table .vc-table--table-token__rotation__hash-row {
    vertical-align: top;
    height: $space-4;
}

.vc-table .vc-table--table-token__rotation__deprecated td {
    @extend .no-border;
    color: $grey3;
}

.vc-table--table-token__rotation .vc-table--table-token__rotation__deprecated td:last-child span {
    @extend .ml4;
}

.table-token__copy-keys kbd {
    @extend .no-border, .mainColor;
    min-width: 10px;
    min-height: 10px;
    padding: 3px;
}

.vc-table--table-token__rotation .vc-table--table-token__rotation__new-token-row td,
.vc-table .vc-table--table-token__rotation__deprecated td,
.vc-table--table-token__rotation__input-copy {
    height: 30px;
}

.vc-table.vc-table--table-token__rotation .vc-table--table-token__rotation__deprecated {
    td {
        @extend .border-top;
        border-color: $grey1;
        &.vc-table--settings--api-tokens__actions {
            @extend .no-border;
        }
    }
    & + .vc-table--table-token__rotation__deprecated td {
        @extend .no-border;
    }
}

.vc-table.vc-table--table-token__rotation .vc-table--table-token__rotation__rotated {
    td {
        @extend .border-top, .py3;
        border-color: $grey1;
        &.vc-table--settings--api-tokens__actions {
            @extend .no-border;
        }
    }
}

.vc-table--settings--api-tokens .vc-table--settings--api-tokens__new-token-hash td {
    @extend .py2;
    border-bottom: none;
}

.table-token__rotation__finalize {
    @extend .ml-auto;
    width: 175px;
}

.vc-table--settings--api-tokens .vc-table--settings--api-tokens__name {
    @extend .h4, .regular;
}

.vc-table--settings--api-tokens__expiration span,
.vc-table--settings--api-tokens__created span,
.vc-table--settings--api-tokens__last-used span {
    @extend .monospace, .fz12;
}
