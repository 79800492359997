/*
Naming Convention

Due to the ubiquitous nature of setting padding, these utilities use a shorthand naming convention.
Shorthand 	Description
p 	Padding
t 	Top
r 	Right
b 	Bottom
l 	Left
x 	X-axis (left and right)
y 	Y-axis (top and bottom)
0 	0 reset
1 	$space-1 (default 5px)
2 	$space-2 (default 10px)
3 	$space-3 (default 20px)
4 	$space-4 (default 40px)
*/

.p0 {
    padding: 0;
}
.pt0 {
    padding-top: 0;
}
.pr0 {
    padding-right: 0;
}
.pb0 {
    padding-bottom: 0;
}
.pl0 {
    padding-left: 0;
}
.px0 {
    padding-left: 0;
    padding-right: 0;
}
.py0 {
    padding-bottom: 0;
    padding-top: 0;
}
.p1 {
    padding: $space-1;
}
.pt1 {
    padding-top: $space-1;
}
.pr1 {
    padding-right: $space-1;
}
.pb1 {
    padding-bottom: $space-1;
}
.pl1 {
    padding-left: $space-1;
}
.px1 {
    padding-left: $space-1;
    padding-right: $space-1;
}
.py1 {
    padding-bottom: $space-1;
    padding-top: $space-1;
}
.p2 {
    padding: $space-2;
}
.pt2 {
    padding-top: $space-2;
}
.pr2 {
    padding-right: $space-2;
}
.pb2 {
    padding-bottom: $space-2;
}
.pl2 {
    padding-left: $space-2;
}
.px2 {
    padding-left: $space-2;
    padding-right: $space-2;
}
.py2 {
    padding-bottom: $space-2;
    padding-top: $space-2;
}
.p3 {
    padding: $space-3;
}
.pt3 {
    padding-top: $space-3;
}
.pr3 {
    padding-right: $space-3;
}
.pb3 {
    padding-bottom: $space-3;
}
.pl3 {
    padding-left: $space-3;
}
.px3 {
    padding-left: $space-3;
    padding-right: $space-3;
}
.py3 {
    padding-bottom: $space-3;
    padding-top: $space-3;
}
.p4 {
    padding: $space-4;
}
.pt4 {
    padding-top: $space-4;
}
.pr4 {
    padding-right: $space-4;
}
.pb4 {
    padding-bottom: $space-4;
}
.pl4 {
    padding-left: $space-4;
}
.px4 {
    padding-left: $space-4;
    padding-right: $space-4;
}
.py4 {
    padding-bottom: $space-4;
    padding-top: $space-4;
}
.pl-auto {
    padding-left: auto;
}
.pr-auto {
    padding-right: auto;
}
