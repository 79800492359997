.dropdown--toggler-dropdown__wrapper {
    @extend .cursor-normal;
}

.toggler-dropdown__select-all {
    width: 100px;
}

.toggler-dropdown__wrapper__checkbox {
    width: 0;
}

.dropdown--toggler-dropdown {
    width: fit-content;
}

.dropdown--toggler-dropdown--open {
    @extend .bg-grey05;
}
