.table-scrollhint {
    @extend .relative;
    &:before,
    &:after {
        @extend .absolute, .full-height, .top-0;
        content: '';
        width: $space-2;
    }
    &:before {
        @extend .left-0;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) $space-2);
    }
    &:after {
        @extend .right-0;
        background: linear-gradient(to left, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0) $space-2);
    }
    .vc-table {
        background: linear-gradient(
                to right,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 9px,
                rgba(255, 255, 255, 0) $space-2
            ),
            linear-gradient(
                to left,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 9px,
                rgba(255, 255, 255, 0) $space-2
            );
    }
}
