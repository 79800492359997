.form__label {
    width: $form-label-width;
}

.form__label--margin {
    margin-left: $form-label-width;
}

.form__switch,
.form__label,
.field-password .icon__wrapper,
.form__link {
    height: $form-element-height;
}

.form__actions {
    @extend .right-align;
    margin-left: $form-label-width;
    &.form__actions--dialog {
        @extend .ml0;
    }
}

.form__actions > * + * {
    @extend .ml2;
}

.horizontal-layout {
    //We use $from-label-width as 250px but it's an arbitrary number.
    grid-template-columns: $form-label-width 1fr;
    grid-template-rows: 1fr;
}

.field-password,
.field-inline-labels.field-inline-labels--tooltip .form__input-container {
    grid-template-columns: 1fr auto;
    grid-column-gap: $space-2;
}

.form__input-container__prepend,
.form__input-container__append {
    &:empty {
        @extend .p0;
    }
}

.field-inline-labels .form__input-container,
.field-inline-labels .form__select {
    grid-template-columns: auto 1fr auto;
}

.field-inline-labels.field-inline-labels--tooltip .form__input-container__error,
.field-inline-labels.field-inline-labels--tooltip .form__input-container__explain {
    @include grid-column(1);
}

.field-password .icon__wrapper,
.field-inline-labels .form__input-container__error,
.field-inline-labels .form__input-container__explain,
.form__select .form__input-container__explain,
.form__select .form__input-container__error {
    @include grid-column(2);
}

.field-password .icon__wrapper {
    @include grid-row(1);
}

.field-inline-labels .form__input-container__error,
.form__checkbox {
    @include grid-row(2);
}

.field-inline-labels .form__input-container__explain {
    @include grid-row(3);
}

.field-inline-labels--append-only .form__input-container__error {
    grid-column: 1 / 3;
}

.field-inline-labels--monospace .form__input-container {
    grid-template-columns: max-content min-content max-content;
}

.field-inline-labels--monospace .form__input-container__error,
.field-inline-labels--monospace .form__input-container__explain {
    grid-column: 2 / 4;
}

.form__input-container + .form__input-container {
    @extend .mt3;
}

.form__select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 25px;
}

.form__select .icon__wrapper {
    top: 9px;
}

.field-slideswitch-viariable-help > .grid {
    @extend .grid-align-center;
    grid-template-rows: $form-element-height 1fr;
}

//Forms label width is changed inside dialogs since it's too big
.dialog__container__content {
    $form-label-width: 130px;
    .form__label {
        width: $form-label-width;
    }

    .form__label--margin {
        margin-left: $form-label-width;
    }

    .form__actions:not(.form__actions--dialog) {
        margin-left: $form-label-width;
    }

    .horizontal-layout {
        //We use $from-label-width as 120px but it's an arbitrary number.
        grid-template-columns: $form-label-width 1fr;
    }
    input,
    textarea,
    select {
        @extend .bg-white;
    }
}

.settings__form__help {
    @extend .pl1, .cursor-hand, .line-height-120;
}
