.vc-bouncer {
    @extend .inline-flex, .nowrap;
}

.spinner {
    @extend .center;
    width: 35px;
}

.spinner > div {
    @extend .circle, .inline-block, .bg-MainColor;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    height: 9px;
    width: 9px;
}

.spinner .bounce1 {
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    animation-delay: -0.16s;
}

@include keyframes(sk-bouncedelay) {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

.vc-bouncer {
    &.white .spinner > div {
        @extend .bg-white;
    }
    &.grey2 .spinner > div {
        @extend .bg-grey2;
    }
}

.vc-loader {
    top: 40vh;
    left: calc(50% - #{$nav-width});
    transform: translateX(-50%);
}

.vc-loader__overlay {
    opacity: 0.8;
}

.sk-folding-cube {
    @extend .my3, .mx-auto, .relative;
    width: $space-4;
    height: $space-4;
    transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
    @extend .float-left, .relative;
    width: 50%;
    height: 50%;
    transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
    @extend .absolute, .top-0, .left-0, .full-width, .full-height, .bg-MainColor;
    content: '';
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
    transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
    transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
    transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
    animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
    animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
    animation-delay: 0.9s;
}

@include keyframes(sk-foldCubeAngle) {
    0%,
    10% {
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%,
    75% {
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%,
    100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}
