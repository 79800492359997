.settings__form--query-data {
    //arbitrary number to set the inputs width
    max-width: 750px;
}

.settings__form__collect-metadata {
    @extend .align__top--2;
    //80px is an arbitrary number to prevent the checkbox from overlaping
    left: $form-label-width + 80px;
}

.settings__form--query-data__delimiters input {
    width: $space-4;
}
