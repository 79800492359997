.editable-input__content {
    &:hover .icon__wrapper {
        opacity: 1;
    }
}

.editable-input__content .icon__wrapper {
    opacity: 0;
    transition: opacity $duration;
}

.editable-input__dialog {
    top: 50%;
    transform: translateY(-50%);
}

.editable-input__dialog__input {
    height: 25px;
    min-width: 0;
}

.editable-input__dialog__actions svg {
    @extend .relative;
    top: 3px;
}
