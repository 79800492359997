@mixin gravatar() {
    @extend .circle, .flex, .items-center, .justify-center, .overflow-hidden;
    background-color: $mainColor;
    //img mode
    & > img {
        height: 100%;
        width: 100%;
    }
    //initials mode
    & > span {
        color: white;
    }
}

.gravatar {
    @include gravatar();
}

.gravatar--big {
    @include gravatar();
    height: 64px;
    width: 64px;
    min-height: 64px;
    min-width: 64px;
    //initials mode
    & > span {
        @extend .h1;
    }
}

.gravatar--medium {
    @include gravatar();
    height: 48px;
    width: 48px;
    min-height: 48px;
    min-width: 48px;
    //initials mode
    & > span {
        @extend .h1;
    }
}

.gravatar--small {
    @include gravatar();
    height: 36px;
    width: 36px;
    min-height: 36px;
    min-width: 36px;
    //initials mode

    & > span {
        @extend .h4;
    }
}

.member__icon {
    .popover > .icon__wrapper .icon {
        font-size: 45px;
        fill: transparent;
    }
}
