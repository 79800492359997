.export-toggler .dropdown__label {
    @extend .flex, .items-center, .px2, .py1, .rounded, .ml2, .relative, .z2;
    background-color: white;
}

.export-toggler > .open .dropdown__label {
    transition: background-color $duration;
    background-color: $grey05;
}

.export-toggler .dropdown-content {
    width: 320px;
    @extend .right-0, .z1;
}

.export-toggler__download-button {
    width: 30px;
}

.export-toggler__download-error vc-icon {
    top: 2px;
}
