.licenses__input {
    width: 75px;
}

.vc-viz-background-bar--licenses {
    height: 20px;
}
.settings__licenses {
    max-width: 700px;
}

.viz__licenses--orange .viz__licenses__left {
    @extend .orange;
}

.viz__licenses--orange .vc-viz-background-bar {
    @extend .bg-orange;
}

.viz__licenses--red .viz__licenses__left {
    @extend .red;
}

.viz__licenses--red .vc-viz-background-bar {
    @extend .bg-red;
}

.licenses__alerts > .alert {
    @extend .py3;
}

.licenses__alerts .alert__close-icon {
    @extend .h2;
}

.dialog__container__content__add-licenses {
    //Arbitrary width fo the dialog
    max-width: 700px;
}

.review-licenses__valid {
    max-width: 640px;
}

.environment-count {
    @extend .dark, .fz13, .nowrap, .mr3, .ml2, .right-align;
    min-width: 65px;
}

.license_title {
    @extend .flex, .items-center, .overflow-hidden, .nowrap;
    text-overflow: ellipsis;
    font-weight: bold;

    &:hover {
        @extend .underline;
    }
}

.license_breakdown {
    @extend .px2, .flex, .items-center, .justify-between, .border-bottom, .border-color-grey1;
    height: 40px;
}

.environments_widget {
    max-height: 466px;
}
