//Standard animations

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

@include keyframes(fade-in) {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(fade-out) {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@include keyframes(drop-down) {
    0% {
        max-height: 0;
        opacity: 0;
    }
    100% {
        max-height: 100vh;
        opacity: 1;
    }
}

@include keyframes(fade-width) {
    0% {
        max-width: 0;
        opacity: 0;
    }
    100% {
        max-width: 100vw;
        opacity: 1;
    }
}

@include keyframes(clockwise-spin) {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@include keyframes(clockwise-spin--refresh) {
    0% {
        transform: rotate(0deg);
    }

    5%,
    100% {
        transform: rotate(360deg);
    }
}

@include keyframes(rotate-clockwise-90) {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(90deg);
    }
}

@include keyframes(rotate-counterclockwise-90) {
    0% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(0);
    }
}

@include keyframes(rotate-clockwise-180) {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(180deg);
    }
}

@include keyframes(rotate-counterclockwise-180) {
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(0);
    }
}

@include keyframes(pulse) {
    0% {
        background-color: $white;
    }
    50% {
        background-color: $orange;
    }
    100% {
        background-color: $white;
    }
}

@include keyframes(flash) {
    0% {
        opacity: 0;
    }
    10%,
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes shine {
    0% {
        left: -200%;
    }
    100% {
        left: 100%;
    }
}
