.menu__item__title {
    height: $nav-item-height;
    &:hover {
        transition: background-color $duration;
        background-color: rgba(109, 122, 125, 0.1);
    }
    & > svg {
        fill: $centerGrey;
    }
}

.menu__item--selected {
    &.menu__item__title {
        @extend .cursor-normal, .bg-vantaDark, .swiOrange;
        &:hover {
            @extend .bg-vantaDark;
        }
        svg {
            fill: $swiOrange;
        }
    }
}

.group__menu__item--selected {
    @extend .relative;
    &:before {
        @extend .absolute, .left-0, .full-height, .bg-swiOrange, .z1;
        content: '';
        width: 2px;
    }
    .menu__item__title__icon svg {
        fill: $swiOrange;
    }
}

.group__menu__item--selected .menu__item__title__tooltip {
    @extend .swiOrange;
}

.menu__item__title__beta {
    top: 28px;
    left: 8px;
}

.extra-notification {
    @extend .circle, .border, .border-color-white;
    width: 13px;
    height: 13px;
    svg {
        @extend .display-none;
    }
    &.extra-notification--warning {
        @extend .bg-orangeMessage;
    }
    &.extra-notification--error {
        @extend .bg-red;
    }
}

.settings__nav .extra-notification {
    @extend .no-border, .relative, .align__top--1, .flex, .flex-grow-1, .justify-end, .items-center, .fz18;
    background-color: transparent;
    svg {
        @extend .block;
    }
    &.extra-notification--warning svg {
        fill: $orangeMessage;
    }
    &.extra-notification--error svg {
        fill: $red;
    }
}

.user-tools__logout__button:hover span {
    @extend .underline;
}

.menu__item__title__list .main_menu {
    background-color: $vantaDark;
}

.menu__item__title ~ .menu__item__title__list .menu__item__title {
    padding-left: 58px;
}

.navigation__group__title + .menu__item__title__list {
    max-height: 120px;
    transition: max-height $long-duration;
}

.navigation__group__title--shown + .menu__item__title__list {
    max-height: auto;
}

.navigation__group__title--current:hover,
.navigation__group__title--shown:hover {
    background-color: #232323;
}

.navigation__group__title--shown,
.navigation__group__title--current {
    .menu__item__title__icon svg {
        fill: $swiOrange;
    }
}

.navigation__group__title--hidden + .menu__item__title__list {
    max-height: 0;
    transition: max-height 0s;
}

.menu__item__title__arrow {
    transition: transform $long-duration;
}

.navigation__group__title.navigation__group__title--shown > .menu__item__title__arrow {
    transform: rotate(180deg);
}

.sidebar {
    @extend .flex, .flex-column;
    height: calc(100% - #{$header-height});
    width: $nav-width;
    transition: width $long-duration;
}

//Collapse behavior

//Navigation Tooltips
.menu__item__title__tooltip {
    @extend .fixed, .flex, .items-center;
    left: calc(#{$nav-width-minimized} + 20px);
    border-radius: $tile-radius;
    box-shadow: $tile-shadow;
    min-height: $nav-item-height;
    margin-top: -$nav-item-height;
    &::before {
        content: '';
        @extend .absolute;
        border: 8px solid $paleDark;
        top: 12px;
        left: -16px;
        border-color: transparent $paleDark transparent transparent;
    }
    &::after {
        //this is to prevent the tooltip to disapear when hovering between the arrow tip and the icon
        content: '';
        @extend .absolute, .full-height, .top-0, .transparent;
        left: -$space-3;
        width: $space-3;
    }
}

.menu__item__title__tooltip--dropdown {
    transform: translateY(-$nav-item-height);
    left: calc(#{$nav-width-minimized} + 25px);
    &::before {
        top: 52px;
    }
}

.menu__item__title__tooltip--dropdown .dropdown__content {
    top: $nav-item-height;
}

.sidebar .sidebar__toggle {
    @extend .absolute, .right-0;
    bottom: 10px;
    min-width: 35px;
    height: 35px;
    &:hover svg {
        transition: fill $duration;
        fill: $white;
    }
}

.sidebar__toggle .icon__wrapper {
    top: 6px;
    left: 2px;
    & + .icon__wrapper {
        left: 9px;
    }
}

.navigation__list__nav-section {
    @extend .py3, .border-bottom, .border-color-semi-dark;
}

.navigation__group + .navigation__list__nav-section {
    @extend .mt3, .border-top, .border-color-semi-dark;
}

.sidebar--collapsed {
    width: $nav-width-minimized;
    //Environment Selector
    .env-selector__toggler {
        @extend .justify-center;
    }
    .env-selector__toggler .dropdown__label {
        @extend .absolute;
    }
    .env-selector__toggler .env-selector__toggler__env-name,
    .env-selector__toggler .env-selector__toggler__org-name,
    .env-selector__toggler .env-selector__toggler__arrow {
        @extend .display-none;
    }
    .env-selector__toggler .env-selector__toggler__org-icon {
        @extend .m0;
    }
    //Nav Items
    .menu__item__title,
    .navigation__group--quick-add__button,
    .navigation__group--upgrade__button {
        @extend .p0, .justify-center;
    }
    .navigation__group__title .menu__item__title__icon,
    .menu__item__title__icon,
    .icon__wrapper {
        @extend .m0;
    }
    .menu__item__title > a,
    .user-tools__logout__button > a,
    .menu__item__title > span,
    .user-tools__logout__button > span,
    .menu__item__title__arrow,
    .navigation__group--quick-add__button__label,
    .navigation__group--upgrade__button__label {
        //secondary class is used to only affect the navigation and not the settings
        @extend .display-none;
    }
    .navigation__group__title--shown + .menu__item__title__list {
        max-height: 0;
    }
    .menu__item__title__beta,
    .menu__item__title__icon--upgrade--close {
        left: 13px;
    }
    .sidebar__toggle {
        @extend .centerX;
    }
    //tooltips
    .menu__item__title__tooltip--dropdown .menu__item__title {
        @extend .justify-start, .px3;
        &:hover {
            @extend .bg-vantaDark;
        }
    }
    .group__menu__item--selected .menu__item__title__tooltip--dropdown__header {
        @extend .swiOrange;
    }

    //Main content
    & + .main {
        margin-left: $nav-width-minimized;
        .filters {
            padding-right: $nav-width-minimized;
        }
        //Loader
        .vc-loader {
            left: calc(50% - #{$nav-width-minimized});
        }
        //Notebooks
        .notebooks__sidebar {
            left: $nav-width;
        }
        //Profiler
        .profiler__fixed-header {
            width: calc(100% - #{$nav-width-minimized});
        }
        .view__footer {
            width: calc(100% - #{$nav-width-minimized});
            left: $nav-width-minimized;
        }
    }
}

//Navigation Quick Add button
.navigation__group--quick-add .dropdown__content,
.navigation__group--upgrade .dropdown__content {
    @include tip-left($paleDark);
    @extend .rounded, .bottom-0, .top-auto;
    animation: fade-in $long-duration forwards;
    left: 100%;
    width: 200px;
    &::after {
        bottom: 15px;
    }
}

.navigation__group--quick-add__button,
.navigation__group--upgrade__button {
    @extend .no-decoration, .full-width, .flex, .items-center, .z2, .px3;
    @include hover($paleDark);
    //arbitrary height taken from mockups
    height: 50px;
}

.menu__item__title__icon--quick-add svg {
    transition: transform $long-duration;
    @extend .circle, .cursor-hand, .bg-swiOrange;
}

.navigation__group--quick-add--open {
    .navigation__group--quick-add__button {
        @extend .bg-vantaDark;
    }
    .menu__item__title__icon--quick-add {
        svg {
            transform: rotate(45deg);
        }
    }
}

.navigation__group--quick-add__item:hover {
    @extend .bg-vantaDark;
    span {
        @extend .swiOrange;
    }
}

// Navigation Trial Box
.navigation__group--upgrade {
    @include hover($paleDark);
}
.navigation__group--upgrade .dropdown__content {
    @extend .bottom-0, .top-auto;
    left: 100%;
    width: 385px;
}

.menu__item__title__icon--upgrade {
    background-color: #fd776e;
    padding: 6px;
}

.navigation__group--upgrade:not(.navigation__group--upgrade--open) {
    &:hover .menu__item__title__icon--upgrade {
        background-color: lighten(#fd776e, 5%);
        @extend .shadow;
    }
}

.navigation__group--upgrade--open {
    transition: background-color $duration;
    background-color: $vantaDark;
}

.navigation__group--upgrade .menu__item__title__icon--upgrade svg,
.navigation__group--upgrade .menu__item__title__icon--upgrade--close svg {
    transition: transform $long-duration;
}

.navigation__group--upgrade .menu__item__title__icon--upgrade--close {
    @extend .left-3;
}

.navigation__group--upgrade .menu__item__title__icon--upgrade--close svg,
.navigation__group--upgrade--open .menu__item__title__icon--upgrade svg {
    transform: scaleX(0);
}

.navigation__group--upgrade--open .menu__item__title__icon--upgrade--close svg {
    transform: scaleX(1) rotate(45deg);
}

.add_dropdown .dropdown__content,
.navigation__group--upgrade__dropdown .dropdown__content {
    @extend .ml3;
}
