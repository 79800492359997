.vc-table--anomalies__header__trash {
    width: 80px;
}

.anomalies__metric-search {
    width: 310px;
}

.anomaly__header__dropdown {
    @extend .p0, .bg-MainColor, .rounded, .my1, .ml3;
    .dropdown {
        @extend .white, .bold, .fz12;
        &:hover {
            @include hover($mainColor);
        }
    }
    .dropdown > .icon__wrapper {
        @extend .top-0;
        svg {
            fill: $white;
        }
    }
    .dropdown--open {
        @extend .bg-MainColor, .rounded;
        clip-path: initial;
        box-shadow: none;
        &:hover {
            @include hover($mainColor);
        }
    }
    .dropdown__content {
        @extend .mt3, .rounded, .border, .border-color-grey1;
        // number extracted from the mockups
        min-width: 540px;
    }
    .hosts-selector--form {
        @extend .z1;
    }
}
