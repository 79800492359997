.env-selector {
    //arbitrary number taken from the mockup
    height: 70px;
}

.env-selector__toggler:hover {
    transition: background-color $duration;
    background-color: rgba(109, 122, 125, 0.1);
}

.env-selector__content__list__item {
    //arbitrary width taken from mockups
    max-width: 278px;
}

.env-selector .env-selector__toggler__arrow {
    transition: transform $long-duration;
}

.env-selector.env-selector--open .env-selector__toggler__arrow {
    transform: rotate(180deg);
}

.env-selector .dropdown__content {
    @extend .top-2, .bg-paleDark, .rounded, .flex;
    left: calc(#{$nav-width} + 25px);
    min-height: 35px;
    width: min-content;
}

.env-selector__content__title {
    height: $space-4;
}

.env-selector__toggler__env__list {
    max-height: 30vh;
}

.env-selector__content__list__item:hover {
    transition: background-color $duration;
    background-color: $vantaDark;
    & > a {
        border-color: $vantaDark;
        color: $swiOrange;
    }
}

.env-selector .dropdown__content {
    @include tip-left($paleDark);
    &:after {
        top: 19px;
    }
}

.env-selector .dropdown--right {
    @extend .static;
}

.create-env-button {
    @extend .capitalize, .regular;
    //arbitrary width taken from mockups
    width: 258px;
}
