.tab__heading {
    @extend .cursor-hand, .relative;
    margin-bottom: -2px;
    display: inline-flex;
}

.tab__heading__title {
    &:after {
        content: '';
        @extend .border-bottom, .border-thick, .absolute, .left-0, .bottom-0, .full-width;
        border-color: transparent;
        transition: border-color $duration;
        transform: scaleX(0);
        transform-origin: center;
    }
}

.tab__heading.active .tab__heading__title {
    @extend .mainColor, .bold;
    &:after {
        border-color: $mainColor;
        transform: scaleX(1);
        transition: transform 0.3s, border-color 0.3s;
    }
}

.tab-panel {
    animation: fade-in $slow-easing forwards;
}

.tabs--credentials .tab__heading.active .tab__heading__title {
    @extend .dark;
}
