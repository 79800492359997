.header__tool__dropdown--changelog .dropdown__content {
    @extend .overflow-hidden, .cursor-normal;
    height: calc(100vh - 140px);
}

.header__tool__dropdown--changelog__content--documentation {
    width: 410px;
    @extend .overflow-auto, .full-height;
}

.header__tool__dropdown--changelog__content__wrap .changelog__item:last-of-type {
    @extend .mb0;
    .learn-more {
        @extend .no-border;
    }
}

.documentation a {
    @extend .cursor-hand;
}

.documentation-header {
    a:hover {
        @extend .no-decoration;
    }
}

.documentation-posts,
.documentation-post,
.topics-wrap {
    ul {
        @extend .list-reset;
    }
}

.documentation-post {
    img {
        height: auto;
        @extend .full-width;
    }
}

.header__tool__dropdown--changelog__content--documentation__header {
    //Arbitrary number to prevent zoomed browser to show the border
    height: 47px;
    @extend .bg-white, .border--dropdown, .right-0, .rounded-bottom;
}

.documentation-main {
    @extend .relative;
    top: -1px;
    height: calc(100% - 47px); //47 is the title height
}

.documentation-main__breadcrumbs__item {
    &:after {
        @extend .pl1;
        content: '/';
    }
    @extend .inline-block, .grey3, .fz12;
}

.documentation-main__breadcrumbs__item + .documentation-main__breadcrumbs__item {
    @extend .pl1, .relative;
}

.documentation-post__post > * {
    @extend .ml2;
}

.documentation-post__post > * + * {
    @extend .mt2, .ml2;
}

.documentation-post__post table {
    @extend .block, .overflow-auto;
    th {
        @extend .nowrap, .left-align;
    }
    td,
    th {
        @extend .p1, .border-bottom, .border-right;
        border-color: $grey1;
        min-width: 150px;
        &:last-child {
            border-right: none;
        }
    }
    tr:last-child td {
        border-bottom: none;
    }
}

.documentation-post__post {
    h1,
    h2,
    h3,
    h4 {
        @extend .mt3, .mb2, .bold, .dark, .fz14, .mx0;
    }
}

.documentation-post__post pre code {
    color: white;
}

.documentation-post__post p {
    @extend .line-height-120;
}
