@mixin primary($color) {
    @extend .rounded, .uppercase, .bolder, .cursor-hand, .spacing, .variable-width;
    font-size: 0.65rem;
    height: $form-element-height;
    line-height: $form-element-height;
    padding: 0 15px;
    background-color: $color;
    transition: background-color $duration;
    &:hover {
        @include hover($color);
    }
    &:active {
        background-color: darken($color, 10%);
    }
    &:disabled {
        background-color: lighten($color, 10%);
        opacity: 0.5;
        &:hover {
            @extend .cursor-normal;
            background-color: lighten($color, 10%);
        }
    }
    .vc-bouncer {
        @extend .white;
    }
}

@mixin secondary($color) {
    @include primary($color);
    font-size: 0.6875rem;
    height: 30px;
    line-height: 30px;
}

.primary-orange {
    @extend .white;
    @include primary($orange);
}
.primary-mainColor {
    @extend .white;
    @include primary($mainColor);
}
.primary-grey {
    @extend .white;
    @include primary($grey3);
}
.primary-grey-pale {
    @extend .white;
    @include primary($grey2);
}
.primary-grey-light {
    @extend .white;
    @include primary($grey1);
}
.primary-red {
    @extend .white;
    @include primary($red);
}
.primary-green {
    @extend .white;
    @include primary($green);
}
.primary-white {
    @extend .mainColor;
    @include primary($white);
    &:hover:hover {
        background-color: $grey0;
    }
    .vc-bouncer {
        @extend .mainColor;
    }
}
.primary-semiDark {
    @extend .white;
    letter-spacing: unset;
    @include primary($semiDark);
    &:hover:hover {
        background-color: $centerGrey;
    }
}
.secondary-orange {
    @extend .white;
    @include primary($orange);
}
.secondary-maincolor {
    @extend .white;
    @include primary($mainColor);
}
.secondary-lightMaincolor {
    @extend .white;
    @include primary($lightMainColor);
}
.secondary-grey {
    @extend .white;
    @include primary($grey3);
}
.secondary-grey-light {
    @extend .grey3;
    @include primary($grey1);
}
.secondary-red {
    @extend .white;
    @include primary($red);
}
.secondary-green {
    @extend .white;
    @include primary($green);
}

.contact-link--button a {
    @extend .no-decoration;
    color: $white;
}
