.settings--org-team {
    @extend .flex;
    min-height: calc(100vh - #{$header-height - $space-4});
}

.settings__section-content__filter-teams {
    //arbitrary number https://www.figma.com/file/cQ2NmVI2Pq12Oqwj1ISrzW/%234243-Gravatars-in-Teams-Settings-view?node-id=11%3A0
    min-width: 385px;
}

.settings__section-content__team__row {
    //row height just to maintain the spacing flow constat
    min-height: 46px;
}

.settings__section-content__team__row__label {
    //arbitrary width to align all row labels
    min-width: 90px;
}

.settings__section-content__team__row__role-dropdown .dropdown__content {
    @extend .rounded-right, .rounded-bottom;
}

.vc-table--org-teams {
    padding-bottom: 35vh;
}

.vc-table--org-teams tr:nth-child(n + 5) .vc-table--org-teams__invite-users .vc-checklist {
    max-height: 30vh;
}

.vc-table--org-teams tr:nth-child(n + 7) .vc-table--org-teams__invite-users .vc-checklist {
    max-height: 20vh;
}

.member__delete {
    @extend .transparent;
    transition: opacity $duration;
}

.member:hover .member__delete {
    opacity: 1;
}

.settings__section-content__team__row .member__count {
    @include hoverdark($grey1);
}

.gravatar--small.member__count {
    @extend .bg-grey1;
}

.settings__form--create-teams {
    width: 510px;
}

.vc-table--org-teams {
    //arbitrary number for the columns to be more readable
    min-width: 750px;
}

.vc-table--org-teams__role {
    min-width: 150px;
}

.vc-table--org-teams__members {
    min-width: 370px;
}

.vc-table--org-teams__members {
    width: 60%;
}

.vc-table--org-teams__envs {
    width: 40%;
    min-width: 280px;
}

.vc-checklist {
    @extend .overflow-auto;
    height: 40vh;
    min-width: 460px;
}
