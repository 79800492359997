/*Icons*/
.icon {
    height: 1em;
    min-width: 1em;
    shape-rendering: auto;
    //Firefox display issue with svg
    -moz-transform: scale(1);
    width: 1em;
    transition: fill $duration;
}

.white > svg.icon {
    fill: $white;
}
.grey0 > svg.icon {
    fill: $grey0;
}
.grey05 > svg.icon {
    fill: $grey05;
}
.grey1 > svg.icon {
    fill: $grey1;
}
.grey2 > svg.icon {
    fill: $grey2;
}
.grey3 > svg.icon {
    fill: $grey3;
}
.centerGrey > svg.icon {
    fill: $centerGrey;
}
.semiDark > svg.icon {
    fill: $semiDark;
}
.moonCrater > svg.icon {
    fill: $moonCrater;
}
.dark > svg.icon {
    fill: $dark;
}
.mainColor > svg.icon {
    fill: $mainColor;
}
.darkMainColor > svg.icon {
    fill: $darkMainColor;
}
.orange > svg.icon {
    fill: $orange;
}
.red > svg.icon {
    fill: $red;
}
.blue > svg.icon {
    fill: $blue;
}
.green > svg.icon {
    fill: $green;
}
.darkGreen > svg.icon {
    fill: $darkGreen;
}
.vantaDark > svg.icon {
    fill: $vantaDark;
}
.paleDark > svg.icon {
    fill: $paleDark;
}
.swiOrange > svg.icon {
    fill: $swiOrange;
}
.swordSteel > svg.icon {
    fill: $swordSteel;
}

.hoverable {
    & > svg.icon {
        transition: fill $long-duration;
    }
    &:active > svg.icon,
    &:active:hover > svg.icon {
        fill: $lightMainColor;
    }
    &:hover > svg.icon {
        @extend .cursor-hand;
        fill: $mainColor;
    }
}

.clickable {
    &:hover > svg.icon {
        @extend .cursor-hand;
    }
}

.status-bulb {
    @extend .circle;
    width: 10px;
    height: 10px;
    &.error,
    &.crit {
        background-color: $red;
    }
    &.ok {
        background-color: $green;
    }
    &.warning,
    &.warn {
        background-color: $orange;
    }
    &.off {
        background-color: $grey2;
    }
    &.info {
        background-color: $blue;
    }
}

/*icon flips*/
.flip-horizontal > svg.icon {
    @include flip-horizontal;
}

.flip-vertical > svg.icon {
    @include flip-vertical;
}

/*Host Icons*/
.host-icon__secondary-icon {
    bottom: -50%;
    right: -50%;
}
