.nunito {
    font-family: 'Nunito', sans-serif;
}

.roboto {
    font-family: 'Roboto mono', sans-serif;
}

.monospace {
    @extend .roboto;
}

.variable-width {
    @extend .nunito;
}

.bold {
    font-weight: bold;
}

.bolder {
    font-weight: 800;
}

.regular {
    font-weight: normal;
}

.lighter {
    font-weight: lighter;
}

.italic {
    font-style: italic;
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.capitalize {
    text-transform: capitalize;
}

.left-align {
    text-align: left;
}

.center {
    text-align: center;
}

.middle {
    vertical-align: middle;
}

.right-align {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.top-align {
    vertical-align: top;
}

.nowrap {
    white-space: nowrap;
}

.pre-wrap {
    white-space: pre-wrap;
}

.break-all {
    word-break: break-all;
}

.underline {
    text-decoration: underline;
    text-decoration-skip-ink: auto;
}

.truncate {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.no-decoration {
    text-decoration: none !important; //important is used to override hover or other effects
}

.line-height-120 {
    line-height: 120%;
    &.monospace {
        line-height: 1.5;
    }
}

@mixin multiline-wrap($lineCount: 2) {
    /* autoprefixer: off */
    @extend .overflow-hidden, .line-height-120;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: $lineCount;
    text-overflow: ellipsis;
    white-space: initial;
    word-break: break-word;
}
