.vc-viz-background-bar {
    height: 8px;
    background-color: $grey2;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFCAMAAABLuo1aAAAAA1BMVEUsr7h6CBnGAAAAC0lEQVR4AWMgDwAAADcAAfv5ipcAAAAASUVORK5CYII=);
    background-repeat: repeat-y;
    @extend .full-width, .rounded;

    &--grey1 {
        background-color: $grey1;
    }
}
