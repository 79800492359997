.vc-table--machines__host .icon__wrapper:not(.host-icon__secondary-icon) {
    font-size: 1.5rem;
}

.servers-table__lastseen__date {
    width: 115px;
}

.vc-table--machines__toggler--disabled .icon__wrapper {
    @extend .display-none;
}

.machines--hidden {
    animation: rotate-clockwise-90 $duration forwards;
}

.machines--shown {
    animation: rotate-counterclockwise-90 $duration forwards;
}

//We need 3 consecutive loading rows to have diminished opacity
@for $i from 1 through 3 {
    .vc-table--machines tr[data-testid='loader-row']:nth-child(#{$i}),
    .vc-table--machines--nodes tr[data-testid='loader-row']:nth-child(#{$i}) {
        opacity: calc(100% - (25% *#{$i}));
    }
}

/*Nodes styles*/

$max-deep-node-level: 7; //it doesn't seem feasible to have more than 7 levels deep but in any case this is stored here.

@for $i from 1 through $max-deep-node-level {
    .vc-table--machines--nodes .level {
        &#{$i} td:first-child {
            //10px is the default table data padding
            padding-left: calc(10px * (#{$i}));
        }
    }
}

.toggler:not(.toggler--expanded) ~ .vc-table--machines__host-name {
    @extend .full-width;
}

/*Agents*/
.vc-table--agents td {
    max-height: 90px;
}

.vc-table--agents th:first-of-type {
    width: 50px;
}

.vc-table--agents__status svg {
    fill: $grey3;
}

.agent-actions__dropdown__button {
    // Arbitrary width to make all buttons align
    width: 80px;
}

.agents-data--agent__label {
    opacity: 0;
    transition: opacity $duration;
}

.agents-data--agent:hover .agents-data--agent__label {
    opacity: 1;
}

.agent__log__content {
    min-height: 20vh;
    @extend .nowrap;
    resize: both;
    &--wrapped {
        white-space: normal;
    }
}

.agents-data--agent__reference {
    width: 100px;
}

.agents-data--agent__sparkline--memory .viz-sparkline path {
    fill: $blue;
    stroke: $blue;
}

.agents-data--agent__sparkline--cpu .viz-sparkline path {
    fill: #d164aa;
    stroke: #d164aa;
}

.sparkline__reference__color {
    width: 16px;
    height: 16px;
}

.sparkline__reference__color--cpu {
    background-color: #d164aa;
}

.sparkline__reference__color--memory {
    background-color: $blue;
}

.referenceRow__reference--agents {
    margin-right: 100px; //compensates number to the right of sparklines
}

.agents__footer tr td,
.vc-table--machines__footer tr td {
    @extend .no-border;
    height: 30px;
}

.filters__agents__input {
    min-width: 300px;
}

/*Edit host dialog*/
.setup__location__footer {
    padding: 15px $space-3;
}

.dialog__host-edit-form,
.dialog__collect-samples,
.dialog__delete-host__desc,
.dialog__edit-credentials {
    width: 560px;
}

.dialog__host-edit-form,
.credential__form__wrapper {
    //arbitrary number to prevent overflowing the dialog outside the browser viewport
    max-height: 460px;
}

.dialog__host-collect-samples-form {
    max-width: 575px;
}

.credential__form__wrapper .field-inline-labels--tooltip:last-child {
    //This padding is needed to avoid the scrollbar from interrupting the hover action over the tooltip
    @extend .pb2;
}

.credential__form__wrapper .popover__content {
    //This arbitrary number prevents the over wrapping of the tooltip content
    min-width: 300px;
}
