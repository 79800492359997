@media print {
    body.bg-grey0 {
        //setting min-width to auto prevents printing overflow
        min-width: auto;
        background-color: transparent;
    }
    header,
    .secondary,
    .filters,
    .notebooks__sidebar,
    vc-notebook-custom-filters.flex,
    .collection__header--notebook {
        display: none;
    }
    header + .main-content-wrp,
    .view.view--filters {
        padding-top: 0;
    }
    .main,
    .notebooks__charts {
        margin: 0;
    }
    .notebooks__charts,
    .notebooks .vc-notebook-content__wrapper {
        padding: 0;
    }
    .notebook__print__header.display-none {
        display: block;
    }
}
