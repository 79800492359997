.settings__nav {
    @extend .border-right, .flex-1;
    //arbitrary number to set the width of the navigation, preventing wrapping of settings nav items
    min-width: 240px;
    max-width: 240px;
    border-color: $grey1;

    .navigation__group--quick-add,
    .navigation__group__title .menu__item__title__icon,
    .menu__item__title__arrow {
        @extend .display-none;
    }

    .navigation__group__title {
        &:hover {
            @extend .cursor-normal;
        }
        &.menu__item__title:hover {
            @extend .bg-white;
        }
    }

    .navigation__group__title + .menu__item__title__list .menu__item__title {
        @extend .px3, .fz16;
        height: 60px;
    }

    .navigation__group__title + .menu__item__title__list .menu__item__title__icon {
        @extend .h2, .mr2;
    }

    .menu__item__title__list {
        max-height: inherit;
    }

    .menu__item--selected.menu__item__title {
        background-color: lighten($mainColor, 50%);
        color: $dark;
        &:hover {
            background-color: lighten($mainColor, 50%);
        }
    }

    .menu__item__title {
        @extend .flex-row, .justify-start, .px3, .dark;
        &:hover {
            background-color: lighten($grey3, 50%);
        }
    }

    .menu__item__title__icon {
        @extend .mr2;
        fill: $grey3;
    }

    .menu__item--selected .menu__item__title__icon > svg {
        fill: $grey2;
    }

    .navigation__group__title {
        @extend .bold, .fz10, .spacing, .uppercase, .flex, .items-center, .px3, .grey3;
        margin-top: 30px;
        height: 30px;
        color: $grey3;
    }

    .navigation__group__title .navigation__tit--env-name {
        @extend .bolder, .inline-block, .dark;
    }
}

.settings__section-content {
    min-width: 0;
}
