.deadlocks {
    @extend .view;
    @extend .flex;
    @extend .flex-column;
    height: calc(100vh - #{$header-height});
}

.viz-deadlock .vc-viz-diagram .vc-viz-diagram-node {
    .icon.warning {
        fill: $white;
    }

    .icon.settings {
        fill: $mainColor;
    }

    .icon.lock {
        fill: $orange;
    }

    .vc-viz-diagram-node__rect {
        fill: $white;
        stroke: $mainColor;
    }

    .vc-viz-diagram-node__label {
        fill: $dark;
    }

    .vc-viz-diagram-node__value {
        fill: $dark;
        font-weight: bold;
    }

    &.vc-viz-diagram-node--highlighted {
        .vc-viz-diagram-node__rect {
            stroke: $red;
        }
        .icon,
        &.vc-viz-diagram-node--selected .vc-viz-diagram-node__rect {
            fill: $red;
        }
    }

    &.vc-viz-diagram-node--selected {
        .vc-viz-diagram-node__label,
        .vc-viz-diagram-node__value,
        .icon {
            fill: $white;
        }
        .vc-viz-diagram-node__rect {
            fill: $mainColor;
        }
    }
}

.viz-deadlock {
    @extend .flex;
    @extend .flex-column;
    @extend .overflow-hidden;
    width: clamp(1600px, 80%, 100%);
}

.vc-table--deadlocks__id {
    width: 130px; // width taken from the mockups
    min-width: 130px;
}

.vc-table--deadlocks__name {
    @extend .full-width;
}

.vc-table--deadlocks__collection {
    width: 185px; // width taken from the mockups
    min-width: 185px;
}

.vc-table--deadlocks td {
    @extend .fz13;
}

.deadlock__table th {
    @extend .left-align, .regular, .fz10, .grey3, .spacing-normal, .pr2, .nowrap;
}

.deadlock__table td {
    @extend .fz12;
    padding: 15px $space-2 0 0;
}

.detail__list__item {
    @extend .flex, .fz12, .py2;
    &:first-child {
        @extend .pt3, .mt2;
    }
    &:last-child {
        @extend .pb3, .mb2;
    }
}

.detail__list__item__title {
    @extend .regular;
    width: 145px; // width taken from the mockups
    min-width: 145px; // This is to prevent flexbox adjusting the width when using long names
}

.viz-deadlock .vc-viz-diagram .vc-viz-diagram__zoom-controls {
    bottom: 20px;
    left: 20px;
}

.deadlock__node-selection {
    //arbitrary width taken from mockups
    width: 485px;
    &.deadlock__node-selection--wide {
        width: 680px;
    }
}

.deadlock__tabs__header h2 {
    animation: fade-in $slow-easing forwards;
}

.deadlock__tabs__header {
    @extend .flex, .border-bottom, .border-color-grey1, .px3, .pt3, .nowrap;
    min-height: 58px; // 58px arbitrary width taken from mockups
}

.deadlock__tabs--sidebar {
    @extend .full-height;
}

.deadlock__tabs--sidebar > .tab-panel {
    @extend .overflow-auto;
    height: calc(100% + -58px); // 58px is the height of the header part
}

.deadlock__tabs .tab__heading {
    @extend .my0, .nowrap;
}

.deadlock__tabs .tab__heading:not(.active) .tab__heading__title strong {
    @extend .regular;
}

.deadlock__tabs .tab__heading__title::after {
    border-width: 5px;
}

.deadlock__table--sidebar td,
.deadlock__tabs .tab__heading__title {
    @extend .py2;
}

.deadlock__table--sidebar th,
.deadlock__table--sidebar td {
    @extend .px2, .border-bottom, .border-color-grey1;
    padding: 15px $space-2;
}

.deadlock__tabs .tab__heading__title .icon__wrapper + strong {
    @extend .ml3;
}

.deadlock__tabs .tab__heading.active svg {
    fill: $mainColor;
}

.deadlock__tabs--details .tab-panel {
    @extend .pt1, .px2; // Separation needed from the tabs header specified in the mockups
}
