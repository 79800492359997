.settings__form--email-pref__list {
    max-width: 520px;
    bottom: 3px;
}

.settings__form--email-pref__row {
    //set to prevent jumping of content
    height: 60px;
}

.half-opacity + .settings__form--email-pref__list__chkbox__label {
    &::before,
    &:after {
        @extend .half-opacity;
        transition: opacity $long-duration;
    }
}

.settings__form--email-pref__list__chkbox__info {
    right: calc(100% + #{$space-2});
    &--bouncer {
        right: calc(100% + #{$space-2});
    }
}

.email-pref-bulk__subscription:hover {
    @extend .underline;
}
