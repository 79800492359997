input[type='checkbox'],
input[type='radio'] {
    @extend .display-none;
}

label > input[type='checkbox'] + span {
    @extend .cursor-hand, .relative, .nowrap;
    padding-left: 26px;
    &:before {
        //empty checkbox square
        @extend .absolute, .align__bottom--1, .left-0, .border, .rounded, .bg-white, .border-color-grey2;
        content: '';
        height: 16px;
        width: 16px;
        transition: background-color $duration, border-color $duration;
    }
    &:after {
        //checkbox tick
        @extend .absolute, .border-right, .border-bottom, .border-thick, .bottom-1;
        content: '';
        left: 6px;
        width: 5px;
        height: 10px;
        border-color: transparent;
        transform: rotate(45deg);
        transition: border-color $duration;
    }
    //checkbox hover state
    &:hover {
        &:after {
            @extend .border-color-grey1;
        }
    }
}

label > input[type='checkbox']:checked {
    //checkbox checked state
    & + span {
        &:before {
            //empty checkbox square
            @extend .bg-MainColor, .border-color-MainColor;
        }
        &:after {
            //checkbox tick
            @extend .border-color-white;
        }
    }
    &:disabled + span,
    &[disabled] + span {
        //checkbox disabled state
        @extend .cursor-normal;
        &:before {
            //empty checkbox square
            @extend .bg-grey1;
        }
        &:hover {
            //checkbox tick
            &:after {
                @extend .border-color-white;
            }
        }
    }
}

label > input[type='checkbox'] {
    //checkbox unchecked state
    &:disabled + span,
    &[disabled] + span {
        //checkbox disabled state
        @extend .cursor-normal;
        &:before {
            //empty checkbox square
            @extend .bg-grey1;
        }
        &:hover {
            //checkbox tick
            &:after {
                @extend .border-color-grey1;
            }
        }
    }
}

label > input[type='radio'] + span {
    @extend .cursor-hand, .relative, .nowrap;
    padding-left: 26px;
    &:before {
        //empty radio circle
        @extend .absolute, .left-0, .border, .circle, .border-thick, .bg-white, .border-color-grey2;
        content: '';
        height: 16px;
        width: 16px;
        transition: border $duration;
    }
    //radio hover state
    &:hover {
        &:before {
            border-color: lighten($mainColor, 30%);
            border-width: 3px;
        }
    }
}

label > input[type='radio']:checked + span {
    //checkbox checked state
    &:before {
        @extend .border-color-MainColor;
        border-width: 3px;
    }
    &:after {
        @extend .absolute, .circle, .bg-MainColor, .top-1, .left-1;
        content: '';
        height: 6px;
        width: 6px;
    }
}

label > input[type='radio']:disabled + span {
    @extend .cursor-normal;
    &:before {
        @extend .border-color-grey1, .bg-grey1;
    }
}

label > input[type='radio']:checked:disabled + span {
    &:before {
        @extend .bg-white;
    }
    &:after {
        @extend .bg-grey1;
    }
}
