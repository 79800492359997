/* ===General layout 1=== */
.security__container {
    height: 100vh;
}

.security__display-fix {
    padding-top: 30px;
}

.wrapper,
.top-bar,
.footer-bar {
    width: 960px;
}

/* ===Header=== */

.top-bar {
    height: calc(#{$header-height} + #{$space-3});
}

/* ===Content block=== */

.card-block {
    width: 485px;
}

.card-block__login--org {
    width: 740px;
}

.card-block--aws-account,
.card-block--aws-no-account {
    padding: 30px;
}

.card-block--aws-no-account {
    width: 280px;
}

.card-block--aws-account__continue {
    width: 170px;
}

fieldset.error input,
fieldset.error select {
    transition: border-color $long-duration;
    border-color: $red;
}

fieldset.error .red {
    animation: drop-down 0.3s cubic-bezier(0.6, 0.04, 0.98, 0.335) forwards;
}

fieldset.error svg {
    fill: $red;
}

.google-logo {
    @extend .no-shrink;
    height: 46px;
    width: 46px;
    background-image: url('/assets/icons/btn_google_light.svg');
}

.card-block__not-member,
.card-block__get-access,
.card-block__forgot,
.card-block__find-org {
    bottom: -25px;
}

.card-block__login__join {
    width: 310px;
    min-width: 310px;
}

.card-block__login__join__wrap {
    @extend .bg-paleDark;
}

.card-block__login__previous-orgs__org,
.card-block__oauth__mail {
    transition: background-color $duration;
    &:hover {
        background-color: $grey0;
    }
    &:hover .card-block__login__previous-orgs__org__link {
        @extend .underline;
    }
}

.card-block__login__previous-orgs__org__link {
    //width before the login card starts expanding.
    max-width: 345px;
}

.card-block fieldset.error .red {
    animation: drop-down 0.3s cubic-bezier(0.6, 0.04, 0.98, 0.335) forwards;
}

/* ===Recaptcha=== */

#recaptcha_area {
    @extend .overflow-hidden, .block, .rounded;
    width: auto;
    > * {
        @extend .mx-auto;
    }
    #recaptcha_image {
        @extend .overflow-hidden, .border, .border-color-grey1, .rounded;
        background: $white;
        width: 100% !important;
    }
    input {
        @extend .dark, .fz16, .full-width, .rounded, .block, .border, .border-color-grey1;
        background: $white;
        line-height: 28px;
        margin-top: 4px;
        padding: 2px 14px 2px 14px;
        transition: border $duration;
    }
    .options {
        margin-top: 2px;
        div {
            a {
                @extend .regular, .line-height-120;
                color: $dark;
                float: none;
                font-size: 11px;
                &:hover {
                    @extend .underline;
                }
            }
        }
    }
}

.oauth-sign-in a,
.saml-sign-in a {
    @extend .inline-flex;
    width: 185px;
    height: $space-4;
}

.google-divider__text {
    @extend .flex, .justify-center, .relative, .uppercase, .my3;
    font-size: 11px;
    &:before {
        @extend .left-0;
    }
    &:after {
        @extend .right-0;
    }
    &:before,
    &:after {
        @extend .absolute;
        background-color: $grey1;
        content: '';
        height: 1px;
        top: 50%;
        width: 45%;
    }
}

hr.section-divider {
    @extend .border-bottom, .block, .my2;
    border-color: $grey1;
}

.swi-logo {
    height: 42px;
    width: auto;
    shape-rendering: auto;
}

.swi-logo--unsubscribe {
    width: auto;
    height: auto;
}

.card-block fieldset.error .red {
    visibility: visible;
}

.honey-pot {
    @extend .display-none;
}

.contact-us {
    @extend .table-cell;
}

.contact-us .contact-us__method {
    @extend .inline-block;
}

.card-block--slack {
    width: 600px;
}

/* ===sf-app layout=== */

.sf-layout {
    height: 100vh;
}

.sf-layout--unsubscribe .card-block {
    width: 680px;
}

.sf-layout--unsubscribe .card-block__not-member {
    bottom: -25px;
}
