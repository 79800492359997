.health__category--hidden {
    animation: rotate-clockwise-90 0s forwards;
}

.health__category--shown {
    animation: rotate-counterclockwise-90 0s forwards;
}

.health__row__toggle:hover .health__category--shown {
    animation: rotate-counterclockwise-90 $duration forwards;
}
.health__row__toggle:hover .health__category--hidden {
    animation: rotate-clockwise-90 $duration forwards;
}

.health__severity {
    width: 50px;
}

.health__severity__indicator {
    height: 12px;
    width: 12px;
}

.health__severity__indicator--occurrence {
    &.warning {
        background-color: $orange;
        border-color: $orange;
    }
    &.critical {
        background-color: $red;
        border-color: $red;
    }
    &.info {
        background-color: $blue;
        border-color: $blue;
    }
}

.health__summary__list {
    @extend .grid, .justify-evenly, .full-width;
    grid-template-columns: repeat(5, 1fr);
    height: 60px;
    column-gap: $space-3;
}

.landing-card__header--health,
.best-practices__togglers {
    width: 260px; //this number is to make it coincide with the bottom card divider
}

.best-practices__list__item--zeroes {
    .best-practices__list__item__title-wrap {
        @extend .pointer-events;
    }
    .best-practices__list__item__title-wrap__arrow svg {
        fill: $grey2;
    }
    .best-practices__list__item__title-wrap__title {
        @extend .grey2;
    }
}
.health__summary__list__count {
    @extend .full-width, .rounded, .white, .flex, .flex-column, .items-center, .justify-evenly;
    width: 100%;
    height: 60px;
}

.health__occurrence__explain {
    margin-left: 67px;
}

.vc-table--best-practices__occurence {
    width: 50%;
    min-width: 400px;
}

.vc-table--best-practices__status {
    width: 50%;
}

.vc-table--best-practices tr {
    &:hover td {
        background-color: lighten($mainColor, 50%);
        transition: background-color $duration;
    }
    &:hover .vc-table--best-practices__add-comments {
        opacity: 1;
    }
}

.vc-table--best-practices__add-comments {
    opacity: 0;
    transition: opacity $duration;
}

.vc-table__cell--query__digest {
    //this is becuase the query is absolute positioned and the container has no height
    height: $space-3;
}
