.vc-table thead {
    height: 50px;
    background-color: transparent;
    @extend .rounded;
}

.vc-table--tile thead {
    background-color: transparent;
    box-shadow: $tile-shadow;
    border-radius: $tile-radius;
    th {
        background-color: $white;
        &:first-child {
            border-top-left-radius: $tile-radius;
            border-bottom-left-radius: $tile-radius;
        }
        &:last-child {
            border-top-right-radius: $tile-radius;
            border-bottom-right-radius: $tile-radius;
        }
    }
}

.vc-table th {
    @extend .border-left, .left-align, .px2, .regular, .h4, .nowrap, .bg-white, .border-color-grey1, .grey3;
    vertical-align: middle;
    transition: color $duration;
    line-height: 22px;
    min-height: 50px;
    height: 50px;
    &:first-child {
        border-left: none;
        @extend .rounded-left;
    }
    &:last-child {
        @extend .rounded-right;
    }
    &.sortable {
        @extend .cursor-hand;
    }
}

.sorted__icon {
    //alignment of sorted icons
    @extend .relative, .align__top--3, .inline-flex, .grey2;
}

//sortable arrow icons
.sortable {
    .sorted__icon--sorted {
        @extend .display-none;
    }
    .sorted__icon--reverse {
        @extend .display-none;
    }
    &.sorted {
        .sorted__icon {
            @extend .display-none;
        }
        .sorted__icon.sorted__icon--sorted {
            @extend .inline-flex, .grey3;
        }
    }
    &.sorted.reverse {
        .sorted__icon.sorted__icon--reverse {
            @extend .inline-flex, .grey3;
        }
        .sorted__icon.sorted__icon--sorted {
            @extend .display-none;
        }
    }
}

.vc-table--settings thead {
    background-color: transparent;
}

.vc-table--settings th {
    color: $dark;
    @extend .bg-grey0;
}

.vc-table td {
    @extend .border-bottom, .middle, .px2, .h4, .lighter;
    border-color: $grey1;
    color: $dark;
    height: 60px;
}

.vc-table--integrations__status {
    width: 135px;
    min-width: 135px;
}

tfoot vc-viz-graph-legend {
    &:after {
        @extend .absolute, .left-0, .top-0, .full-width, .full-height;
        content: '';
        background: linear-gradient(
            90deg,
            rgba(241, 241, 241, 1) 0%,
            rgba(255, 255, 255, 0) 5%,
            rgba(255, 255, 255, 0) 95%,
            rgba(241, 241, 241, 1) 100%
        );
    }
}
