.h1,
.h2,
.h3,
.h4 {
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
}

.h1 {
    font-size: 2rem;
}

.h2 {
    font-size: 1.5rem;
}

.h3 {
    font-size: 0.9375rem;
    font-weight: bold;
}

.h4 {
    font-size: 0.9375rem;
}

.fz27 {
    font-size: 1.6875rem;
}

.fz18 {
    font-size: 1.125rem;
}

.fz16 {
    font-size: 1rem;
}

.fz15 {
    font-size: 0.9375rem;
}

.fz20 {
    font-size: 1.25rem;
}

.h5,
.fz14 {
    font-size: 0.875rem;
}

.fz13 {
    font-size: 0.8125rem;
}

.fz12 {
    font-size: 0.75rem;
}

.fz11 {
    font-size: 0.6875rem;
}

.h6,
.fz10 {
    font-size: 0.625rem;
    @extend .spacing;
}

.spacing {
    letter-spacing: 0.06rem;
}

.spacing-normal {
    letter-spacing: unset;
}
