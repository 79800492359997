// TODO it would be nice to have specific color names to avoid confusion between different shades
// perhaps using https://colornames.org as a source for naming.
$white: white;
$grey0: #f1f1f1;
$grey05: #f5f5f5;
$grey1: #e5e5e5;
$grey2: #c4c4c4;
$grey3: #6d7a7d;
$centerGrey: #828282;
$hotelExecutive: #bab9b9;
$swordSteel: #bdbdbd;
$semiDark: #4f4f4f;
$moonCrater: #363636;
$dark: #262e30;
$mainColor: #2cafb8;
$darkMainColor: #10939c;
$lightMainColor: #5ccdd5;
$orange: #eba742;
$orangeMessage: #f2994a;
$red: #eb5757;
$blue: #2f80ed;
$green: #27ae60;
$darkGreen: #219653;
$docTagColor: #ef7e71;
$docNewTagColor: #2d71ad;
$compareColor: #979e9f;

//Rebranding colors, probably will substitute some of what we have now.
$vantaDark: #111;
$paleDark: #2a2a2a;
$swiOrange: #f99d1c;

:root {
    --white: #{$white};
    --grey0: #{$grey0};
    --grey05: #{$grey05};
    --grey1: #{$grey1};
    --grey2: #{$grey2};
    --grey3: #{$grey3};
    --centerGrey: #{$centerGrey};
    --hotelExecutive: #{$hotelExecutive};
    --swordSteel: #{$swordSteel};
    --semiDark: #{$semiDark};
    --moonCrater: #{$moonCrater};
    --dark: #{$dark};
    --mainColor: #{$mainColor};
    --darkMainColor: #{$darkMainColor};
    --lightMainColor: #{$lightMainColor};
    --orange: #{$orange};
    --orangeMessage: #{$orangeMessage};
    --red: #{$red};
    --blue: #{$blue};
    --green: #{$green};
    --darkGreen: #{$darkGreen};
    --docTagColor: #{$docTagColor};
    --docNewTagColor: #{$docNewTagColor};
    --compareColor: #{$compareColor};

    //Rebranding colors, probably will substitute some of what we have now.
    --vantaDark: #{$vantaDark};
    --paleDark: #{$paleDark};
    --swiOrange: #{$swiOrange};
}

.white {
    color: $white;
}
.grey0 {
    color: $grey0;
}
.grey05 {
    color: $grey05;
}
.grey1 {
    color: $grey1;
}
.grey2 {
    color: $grey2;
}
.grey3 {
    color: $grey3;
}
.centerGrey {
    color: $centerGrey;
}
.hotelExecutive {
    color: $hotelExecutive;
}
.swordSteel {
    color: $swordSteel;
}
.semiDark {
    color: $semiDark;
}
.moonCrater {
    color: $moonCrater;
}
.dark {
    color: $dark;
}
.mainColor {
    color: $mainColor;
}
.darkMainColor {
    color: $darkMainColor;
}
.lightMainColor {
    color: $lightMainColor;
}
.orange {
    color: $orange;
}
.red {
    color: $red;
}
.blue {
    color: $blue;
}
.green {
    color: $green;
}
.darkGreen {
    color: $darkGreen;
}
.vantaDark {
    color: $vantaDark;
}
.paleDark {
    color: $paleDark;
}
.swiOrange {
    color: $swiOrange;
}

.bg- {
    &transparent {
        background-color: transparent;
    }
    &white {
        background-color: $white;
    }
    &grey0 {
        background-color: $grey0;
    }
    &grey05 {
        background-color: $grey05;
    }
    &grey1 {
        background-color: $grey1;
    }
    &grey2 {
        background-color: $grey2;
    }
    &grey3 {
        background-color: $grey3;
    }
    &CenterGrey {
        background-color: $centerGrey;
    }
    &HotelExecutive {
        background-color: $hotelExecutive;
    }
    &SwordSteel {
        background-color: $swordSteel;
    }
    &semiDark {
        background-color: $semiDark;
    }
    &MoonCrater {
        background-color: $moonCrater;
    }
    &dark {
        background-color: $dark;
    }
    &MainColor {
        background-color: $mainColor;
    }
    &DarkMainColor {
        background-color: $darkMainColor;
    }
    &LightMaincolor {
        background-color: $lightMainColor;
    }
    &orange {
        background-color: $orange;
    }
    &orangeMessage {
        background-color: $orangeMessage;
    }
    &red {
        background-color: $red;
    }
    &blue {
        background-color: $blue;
    }
    &green {
        background-color: $green;
    }
    &darkGreen {
        background-color: $darkGreen;
    }
    &vantaDark {
        background-color: $vantaDark;
    }
    &paleDark {
        background-color: $paleDark;
    }
    &swiOrange {
        background-color: $swiOrange;
    }
}
