.sparklines {
    position: relative;
    padding-top: 10px;
    height: 50px;
    width: 700px;
    line {
        display: none;
    }
    div {
        transform: none !important;
    }
    rect {
        cursor: crosshair !important;
    }
}

.sparklines-tooltip {
    display: none;
    cursor: crosshair;
    position: absolute;
    top: 0px;
    z-index: 10;
}

.cursor-line {
    fill: none;
    stroke: #02bbd0;
    stroke-width: 1px;
    opacity: 0.5;
    visibility: hidden;
}

.cursor-circle {
    fill: #59585e;
    stroke-width: 0;
    shape-rendering: geometricPrecision;
    visibility: hidden;
}

.tooltip__tip {
    @extend .absolute, .block;

    top: -10px;
}

.tooltip__tip--down {
    @extend .border-color-dark, .hidden;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid;
    //interpolate arrow since it's made of border and it's displaced 1px
    transform: translateX(-3px);
}
