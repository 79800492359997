.settings__form--authentication {
    max-width: 700px;
}

.auth_explain {
    max-width: 500px;
}

.oauth-info {
    &:hover {
        background-color: $grey05;
    }
}

.oauth-info > input[type='radio'] + span::before {
    top: 14px;
    left: $space-2;
}

.oauth-info > input[type='radio']:checked + span::after {
    top: 19px;
    left: 15px;
}
